import React, { useState, useEffect } from 'react';
import { TransformWrapper, TransformComponent, useControls } from 'react-zoom-pan-pinch';
import HTMLFlipBook from 'react-pageflip';

const Magazine2023 = () => {
    const [active, setActive] = useState(true);
    const [activebook, setActivebook] = useState(true);
    const [activebook1, setActivebook1] = useState(true);
    const [target, setTarget] = useState(null);

    const handleShowFlip = () => {  
        setActive(true);
        setActivebook(true);
        setActivebook1(false);
    }; 
    const handleShowFlip1 = () => {  
        setActive(true);
        setActivebook(false);
        setActivebook1(true);
    }; 
    useEffect(() => {
        setTimeout(() => {
            setActive(false);
            
        setActivebook(true);
            setActivebook1(true);
            // document.querySelector(".flipOpen").style.display = "none"
        }, 200);
    }, []);

    const handleClose = () => {
        setActive(false); 
        setActivebook(false);
        setActivebook1(false);
    };

    const Controls = () => {
        const { zoomIn, zoomOut, resetTransform } = useControls();
        return (
            <div className="control-btn">
                <div onClick={zoomIn}>+</div>
                <div onClick={zoomOut}>-</div>
                <div onClick={resetTransform}>Reset</div>
            </div>
        );
    };
    return (
        <>
            <div className={`flip-popup active`} style={{ display: active ? 'block' : 'none' }}>
                <div className="closeme" onClick={handleClose}>+</div>

                <div className={`flipOpen activebook`}  style={{ display: activebook ? 'block' : 'none' }}>
                    <HTMLFlipBook
                        width={500}
                        height={680}
                        minWidth={315}
                        maxWidth={1000}
                        minHeight={400}
                        maxHeight={1200}
                        mobileScrollSupport={true}
                        showCover={true}
                        useMouseEvents={true}
                        className="flipPageComponent"
                        onChange={(e) => setTarget(e)}
                        pageBackground="rgb(255, 255, 255)"
                        uncutPages="true"
                    >
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/atf1.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/atf2.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/atf3.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/atf4.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/atf5.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/atf6.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/atf7.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/atf8.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/atf9.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/atf10.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/atf11.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/atf12.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/atf13.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/atf14.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/atf15.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/atf16.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/atf17.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/atf19.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/atf20.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/atf21.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/atf22.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/atf23.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/atf24.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/atf25.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/atf26.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/atf27.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/atf28.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/atf29.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/atf30.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/atf31.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/atf32.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/atf34.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/atf35.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/atf36.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/atf37.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/atf38.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/atf39.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/atf40.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/atf41.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/atf42.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/atf43.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/atf44.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/atf45.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/atf46.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/atf47.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/atf48.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/atf49.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/atf50.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/atf51.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/atf52.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/atf53.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/atf54.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/atf55.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/atf56.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/atf57.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/atf58.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/atf59.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/atf60.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/atf61.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/atf62.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/atf63.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/atf64.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/atf65.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/atf66.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/atf67.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/atf68.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/atf69.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/atf70.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/atf71.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/atf72.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/atf73.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/atf74.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/atf75.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/atf76.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/atf77.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/atf78.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/atf79.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/atf80.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/atf81.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/atf82.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/atf83.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/atf84.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/atf86.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/atf87.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/atf88.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/atf89.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/atf90.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/atf91.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/atf92.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/atf93.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/atf94.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/atf95.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/atf96.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/atf97.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/atf98.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/atf99.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/atf100.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/atf101.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/atf102.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/atf103.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/atf104.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/atf105.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/atf106.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/atf107.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/atf108.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/atf109.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/atf110.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/atf111.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/atf112.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/atf113.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/atf114.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/atf115.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/atf116.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/atf117.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/atf118.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/atf119.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/atf120.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/atf121.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/atf122.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/atf123.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/atf124.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/atf125.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/atf126.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/atf127.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/atf128.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/atf129.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/atf130.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/atf131.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/atf132.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/atf133.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/atf134.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/atf135.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/atf136.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/atf137.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/atf138.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/atf139.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/atf140.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/atf141.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/atf142.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/atf143.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/atf144.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/atf145.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/atf146.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/atf147.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/atf148.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/atf149.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/atf150.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/atf151.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/atf152.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/atf153.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/atf154.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/atf155.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/atf156.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/atf157.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/atf158.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/atf159.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/atf160.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/atf161.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/atf162.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/atf163.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/atf164.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/atf165.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/atf166.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/atf167.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/atf168.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/atf169.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/atf170.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/atf171.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/atf172.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/atf173.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/atf174.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/atf175.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/atf176.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/atf177.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/atf178.jpg" /></div>
                    </HTMLFlipBook>
                </div>
                <div className={`flipOpen activebook1`}  style={{ display: activebook1 ? 'block' : 'none' }}>
                    <HTMLFlipBook
                        width={500}
                        height={680}
                        minWidth={315}
                        maxWidth={1000}
                        minHeight={400}
                        maxHeight={1200}
                        mobileScrollSupport={true}
                        showCover={true}
                        useMouseEvents={true}
                        className="flipPageComponent"
                        onChange={(e) => setTarget()}
                        pageBackground="rgb(255, 255, 255)"
                        uncutPages="true"
                    >
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ftj1.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ftj2.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ftj3.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ftj4.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ftj5.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ftj6.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ftj7.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ftj8.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ftj9.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ftj10.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ftj11.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ftj12.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ftj13.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ftj14.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ftj15.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ftj16.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ftj17.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ftj19.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ftj20.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ftj21.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ftj22.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ftj23.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ftj24.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ftj25.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ftj26.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ftj27.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ftj28.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ftj29.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ftj30.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ftj31.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ftj32.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ftj34.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ftj35.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ftj36.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ftj37.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ftj38.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ftj39.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ftj40.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ftj41.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ftj42.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ftj43.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ftj44.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ftj45.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ftj46.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ftj47.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ftj48.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ftj49.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ftj50.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ftj51.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ftj52.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ftj53.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ftj54.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ftj55.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ftj56.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ftj57.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ftj58.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ftj59.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ftj60.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ftj61.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ftj62.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ftj63.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ftj64.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ftj65.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ftj66.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ftj67.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ftj68.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ftj69.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ftj70.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ftj71.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ftj72.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ftj73.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ftj74.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ftj75.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ftj76.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ftj77.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ftj78.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ftj79.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ftj80.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ftj81.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ftj82.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ftj83.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ftj84.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ftj86.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ftj87.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ftj88.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ftj89.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ftj90.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ftj91.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ftj92.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ftj93.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ftj94.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ftj95.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ftj96.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ftj97.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ftj98.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ftj99.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ftj100.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ftj101.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ftj102.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ftj103.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ftj104.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ftj105.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ftj106.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ftj107.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ftj108.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ftj109.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ftj110.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ftj111.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ftj112.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ftj113.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ftj114.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ftj115.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ftj116.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ftj117.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ftj118.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ftj119.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ftj120.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ftj121.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ftj122.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ftj123.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ftj124.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ftj125.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ftj126.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ftj127.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ftj128.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ftj129.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ftj130.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ftj131.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ftj132.jpg" /></div>
                    </HTMLFlipBook>
                </div>

            </div>
            <div className="d-flex flipSelection">
                <div className=" tab-pane active" id="tab1">
                    <div className="flip-block showflip" data-trigger="1" onClick={handleShowFlip}>
                        <h3>AUGUST 2022 - FEBRUARY-2023</h3>
                        <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/atf1.jpg" alt=""></img>
                    </div>
                    <div className="flip-block  showflip" data-trigger="2" onClick={handleShowFlip1}>
                        <h3>FEBRUARY 2022 - JULY 2022</h3>
                        <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ftj1.jpg" alt=""></img>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Magazine2023
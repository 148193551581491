import React, { useState } from 'react';
import { Link } from 'react-router-dom'
import Magazine2024 from '../Component/Magazine2024'
import Magazine2023 from '../Component/Magazine2023'
import Magazine2022 from '../Component/Magazine2022'
import Magazine2021 from '../Component/Magazine2021'
const Tab = ({ label, onClick, isActive, year } ) => {
    return (
        <li class="nav-item">
        <a class="nav-link"  className={`nav-link  tab ${isActive ? 'active' : ''}`} onClick={onClick}>{year}</a> 
      </li>
      
    );
  };
const Emagazine = () => {
    const [activeTab, setActiveTab] = useState('tab1');
    return (
        <>
            <section className="page-header">
                <div className="page-header-bg"></div>
                <div className="page-header-shape-1"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/page-header-shape-1.png" alt="Loreto Convent School, Delhi Cantt" /></div>
                <div className="container">
                    <div className="page-header__inner">
                        <ul className="thm-breadcrumb list-unstyled ml-0">
                            <li><Link to="/"> Home</Link></li>
                            <li>Magazine </li>
                            <li>E - Magazine </li>
                        </ul>
                        <h2>E - Magazine </h2>
                    </div>
                </div>
            </section>
            <section className="innerbody emagazine">
                <div className="container">
                    <div className="row">
                        <div className='col-md-12'>
                            <div>
                                <div className="tab-list nav nav-pills">
                                {/* <ul class="nav nav-pills" role="tablist">
              <li class="nav-item">
      <a class="nav-link active" href="e-magazine-23-24.aspx">2023-2024</a>
    </li>
    <li class="nav-item">
      <a class="nav-link " href="e-magazine-demo.aspx">2022-2023</a>
    </li>

    <li class="nav-item">
      <a class="nav-link " href="e-magazine-21-22.aspx">2021-2022</a>
    </li>

   <li class="nav-item">
                                <a class="nav-link active" href="e-magazine-20-21.aspx">2020-2021</a>
                            </li>

  </ul> */}

                                    <Tab
                                        label="Tab 1"
                                        year = "2023-2024"
                                        isActive={activeTab === 'tab1'}
                                        onClick={() => setActiveTab('tab1')}
                                    > 
                                        </Tab>
                                    <Tab
                                        label="Tab 2"
                                        year = "2022-2023"
                                        isActive={activeTab === 'tab2'}
                                        onClick={() => setActiveTab('tab2')}
                                    />
                                    <Tab
                                        label="Tab 3"
                                        year = "2021-2022"
                                        isActive={activeTab === 'tab3'}
                                        onClick={() => setActiveTab('tab3')}
                                    />
                                    <Tab
                                        label="Tab 4"
                                        year = "2020-2021"
                                        isActive={activeTab === 'tab4'}
                                        onClick={() => setActiveTab('tab4')}
                                    />
                                </div>
                                <div className="tab-content">
                                    {activeTab === 'tab1' && <div><Magazine2024 /></div>}
                                    {activeTab === 'tab2' && <div><Magazine2023 /></div>}
                                    {activeTab === 'tab3' && <div><Magazine2022 /></div>}
                                    {activeTab === 'tab4' && <div><Magazine2021 /></div>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Emagazine
import { useEffect, useState } from "react";
import React from 'react' 
import { Link } from 'react-router-dom'
import { getCircular } from "../Service/Api";
const Syllabus = () => {
    const [data, setData] = useState([]);
    const [syllabusData, setsyllabusData] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            const syllabusData = await getCircular();
            const dataVal = syllabusData.filter((div) => div.category === "Syllabus"); 
            console.log("get Syllabus: ",dataVal )
            setData(dataVal);
        };
        
       
         
         
        fetchData();
    }, [syllabusData]);
  return (
    <>
    <section className="page-header">
        <div className="page-header-bg"></div>
        <div className="page-header-shape-1"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/page-header-shape-1.png" alt="Loreto Convent School, Delhi Cantt"/></div>
        <div className="container">
        <div className="page-header__inner">
            <ul className="thm-breadcrumb list-unstyled ml-0">
                <li><Link to="/"> Home</Link></li>
                <li> Academics </li>
                <li> Syllabus  </li>
            </ul>
            <h2>Syllabus </h2>
        </div>
        </div>
    </section>
    <section className="innerbody">
   <div className="container">
       <div className="row">
           <div className="col-lg-12">
             <div className="table-responsive innertable camptable">
                    <table className="table">
                        <tbody>
                            <tr>
                                <th> S. No. </th>
                                <th>Class</th> 
                                <th style={{textAlign:"center"}}>View </th>
                            </tr>

                            {data.length > 0 ? (
                            data.map((item, index) => (
                           <tr key={index}>
                                <td>{index+1}</td>
                                <td>{item.title}</td> 
                                <td><Link to={`https://d280nq1n4mqyso.cloudfront.net/api/image/${item?.attachments[0]}`} target="_blank">View</Link> </td>
                            </tr> 
                            ))
                             ): (
                                <tr>
                                <td>1</td>
                                <td>Class</td> 
                                <td><Link to="#" target="_blank">View</Link> </td>
                            </tr> 
                            )}
                        </tbody>
                    </table>
                </div>
           </div>
       </div>
   </div>
</section>
   </>
  )
}

export default Syllabus
import { useEffect, useState } from "react";
import { getNewsInner } from "../Service/Api";
import { Link } from 'react-router-dom'
const News = () => {

   const [data, setData] = useState([]);
   const [filterData, setFilteredData] = useState([]);
   const [selectedYear, setSelectedYear] = useState();
   const [selectedMonth, setSelectedMonth] = useState("All");
   const [monthOptions, setMonthOptions] = useState(["All"]);
   const [yearRanges, setYearRanges] = useState([]);
   const [searchQuery, setSearchQuery] = useState("");

   useEffect(() => {
      const currentYear = new Date().getFullYear();
      const Year = `${currentYear}`;
      setSelectedYear(Year);
      console.log("get current Year", Year);
   }, []);
   useEffect(() => {
       const fetchData = async () => {
           const galleryData = await getNewsInner(); 
           setData(galleryData); 
           const uniqueYears = Array.from(
               new Set(galleryData.flatMap((item) => {
                   if (item.fromDate) {
                       const year = new Date(item.fromDate).getFullYear();
                       return [year];
                   } else {
                       return [];
                   }
               }))
           );
           const uniqueYearRanges = uniqueYears.map(year => `${year}`);
           uniqueYearRanges.sort((a, b) => b - a);
           setYearRanges(uniqueYearRanges);

           const uniquemonth = Array.from(
               new Set(galleryData.flatMap((item) => {
                   if (item.fromDate) {
                       const month = new Date(item.fromDate).getMonth() + 1;
                       return [month];
                   } else {
                       return [];
                   }
               }))
           );
           const uniqueMonths = uniquemonth.map(month => `${month}`);
           uniqueMonths.sort((a, b) => b - a);
           setMonthOptions(uniqueMonths);
       };
       fetchData();
   }, []);

   useEffect(() => {
       let filteredData = data;
       if (selectedYear !== "All") {
           filteredData = filteredData.filter((item) => {
               if (item.fromDate && item.fromDate.trim() !== "") {
                   const year = new Date(item.fromDate).getFullYear();
                   const month = new Date(item.fromDate).getMonth() + 1;
                   return parseInt(selectedYear) === year && (selectedMonth === "All" || parseInt(selectedMonth) === month);
               } else {
                   return false;
               }
           });
       }
       if (selectedMonth !== "All") {
           filteredData = filteredData.filter((item) => {
               if (item.fromDate && item.fromDate.trim() !== "") {
                   const month = new Date(item.fromDate).getMonth() + 1;
                   return parseInt(selectedMonth) === month;
               } else {
                   return false;
               }
           });
       }
       if (searchQuery) {
           filteredData = filteredData.filter((item) =>
               item.title.toLowerCase().includes(searchQuery.toLowerCase())
           );
       }
       setFilteredData(filteredData);
   }, [selectedYear, selectedMonth, searchQuery, data]);

   return (
      <>
         <section className="page-header">
            <div className="page-header-bg"></div>
            <div className="page-header-shape-1"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/page-header-shape-1.png" alt="Loreto Convent School, Delhi Cantt" /></div>
            <div className="container">
               <div className="page-header__inner">
                  <ul className="thm-breadcrumb list-unstyled ml-0">
                     <li><Link to="/"> Home</Link></li>
                     <li> Latest News </li>
                  </ul>
                  <h2>Latest News </h2>
               </div>
            </div>
         </section>
         <section className="innerbody">
            <div className="container">

               <div className="row tabs-dynamic">
                  <div className="col-xxl-3 col-xl-3 col-lg-6 col-12">
                     <div className="count-val">
                        <p>Total Count:{filterData.length} </p>
                     </div>
                  </div>
                  <div className="col-xxl-3 col-xl-3 col-lg-6 col-12">
                     <div className="month-selection">

                     <select  
                                 value={selectedYear}
                                 onChange={(e) => setSelectedYear(e.target.value)}
                                 className="yearSelection">
                                 <option value={"All"}>All</option>
                                 {yearRanges.map((yr) => (
                                    <option key={yr} value={yr}>{yr}</option>
                                 ))}
                              </select>
                     </div>
                  </div>
                  <div className="col-xxl-3 col-xl-3 col-lg-6 col-12">
                     <div className="month-selection">
                     <select
                        value={selectedMonth}
                        onChange={(e) => setSelectedMonth(e.target.value)}
                        className="monthSelect"
                     ><option value={"All"}>All</option>
                        {monthOptions.map((month, index) => (
                           <option key={index} value={month}>{month}</option>
                        ))}
                     </select>
                     </div>
                  </div>

                  <div className="col-xxl-3 col-xl-3 col-lg-6 col-12">
                     <div className="searchBox">
                     <input
                    type="text"
                    id="myInput"
                    name="name"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    placeholder="Search Here.."
                />
                     </div>
                  </div>
               </div>
               <div className="row years year2023 showYear">

                  {filterData.length > 0 &&
                     filterData.map((item, index) => (
                        <div className="col-md-12 newsCount">
                           
                              <div className="inr-news-blk">
                                 <h6 className="date">{item.date}</h6>
                                 <div className="inr-news-content">
                                    <h3>{item.title}</h3>
                                    <p>{item.description}</p>
                                    <h6 className="loc">
                                    {item.attachments && item.attachments.length > 0 && (<Link to={`https://d280nq1n4mqyso.cloudfront.net/api/image/${item?.attachments}`} target="_blank"> <span> View Attachment <i className="bi bi-arrow-right"></i> </span></Link>)}
                                    </h6>
                                 </div>
                              </div>
                           
                        </div>
                     ))}

                  {/* <div className="col-md-12 newsCount" data-month="1">
                       <Link to="/" target="_blank">
                      <div className="inr-news-blk">
                         <h6 className="date">DD MM YYYY</h6>
                         <div className="inr-news-content">
                            <h3>News Heading</h3>
                            <p>Stay connected for more updates!</p>
                            <h6 className="loc"> 
                               <span> View Attachment <i className="bi bi-arrow-right"></i> </span>
                            </h6>
                         </div>
                      </div>
                  </Link>
                 </div>
    
                  
                    <div className="col-md-12 newsCount" data-month="2">
                       <Link to="/" target="_blank">
                      <div className="inr-news-blk">
                         <h6 className="date">DD MM YYYY</h6>
                         <div className="inr-news-content">
                            <h3>News Heading</h3>
                            <p>Stay connected for more updates!</p>
                            <h6 className="loc"> 
                               <span> View Attachment <i className="bi bi-arrow-right"></i> </span>
                            </h6>
                         </div>
                      </div>
                  </Link>
                 </div>
    
                    
                    <div className="col-md-12 newsCount" data-month="2">
                       <Link to="/" target="_blank">
                      <div className="inr-news-blk">
                         <h6 className="date">DD MM YYYY</h6>
                         <div className="inr-news-content">
                            <h3>News Heading</h3>
                            <p>Stay connected for more updates!</p>
                            <h6 className="loc"> 
                               <span> View Attachment <i className="bi bi-arrow-right"></i> </span>
                            </h6>
                         </div>
                      </div>
                  </Link>
                 </div>
    
                  <div className="col-md-12 newsCount" data-month="2">
                       <Link to="/" target="_blank">
                      <div className="inr-news-blk">
                         <h6 className="date">DD MM YYYY</h6>
                         <div className="inr-news-content">
                            <h3>News Heading</h3>
                            <p>Stay connected for more updates!</p>
                            <h6 className="loc"> 
                               <span> View Attachment <i className="bi bi-arrow-right"></i> </span>
                            </h6>
                         </div>
                      </div>
                  </Link>
                 </div>
                  <div className="col-md-12 newsCount" data-month="2">
                       <Link to="/" target="_blank">
                      <div className="inr-news-blk">
                         <h6 className="date">DD MM YYYY</h6>
                         <div className="inr-news-content">
                            <h3>News Heading</h3>
                            <p>Stay connected for more updates!</p>
                            <h6 className="loc"> 
                               <span> View Attachment <i className="bi bi-arrow-right"></i> </span>
                            </h6>
                         </div>
                      </div>
                  </Link>
                 </div>
                  <div className="col-md-12 newsCount" data-month="2">
                       <Link to="/" target="_blank">
                      <div className="inr-news-blk">
                         <h6 className="date">DD MM YYYY</h6>
                         <div className="inr-news-content">
                            <h3>News Heading</h3>
                            <p>Stay connected for more updates!</p>
                            <h6 className="loc"> 
                               <span> View Attachment <i className="bi bi-arrow-right"></i> </span>
                            </h6>
                         </div>
                      </div>
                  </Link>
                 </div>
                  <div className="col-md-12 newsCount" data-month="2">
                       <Link to="/" target="_blank">
                      <div className="inr-news-blk">
                         <h6 className="date">DD MM YYYY</h6>
                         <div className="inr-news-content">
                            <h3>News Heading</h3>
                            <p>Stay connected for more updates!</p>
                            <h6 className="loc"> 
                               <span> View Attachment <i className="bi bi-arrow-right"></i> </span>
                            </h6>
                         </div>
                      </div>
                  </Link>
                 </div>
                    <div className="load-more"><div className="next">Show More</div></div>
                    </div>
                <div className="row years year2022">
                     <div className="col-md-12 newsCount" data-month="1">
                       <Link to="/" target="_blank">
                      <div className="inr-news-blk">
                         <h6 className="date">DD MM YYYY</h6>
                         <div className="inr-news-content">
                            <h3>News Heading</h3>
                            <p>Stay connected for more updates!</p>
                            <h6 className="loc"> 
                               <span> View Attachment <i className="bi bi-arrow-right"></i> </span>
                            </h6>
                         </div>
                      </div>
                  </Link>
                 </div>
                     <div className="col-md-12 newsCount" data-month="2">
                       <Link to="/" target="_blank">
                      <div className="inr-news-blk">
                         <h6 className="date">DD MM YYYY</h6>
                         <div className="inr-news-content">
                            <h3>News Heading</h3>
                            <p>Stay connected for more updates!</p>
                            <h6 className="loc"> 
                               <span> View Attachment <i className="bi bi-arrow-right"></i> </span>
                            </h6>
                         </div>
                      </div>
                  </Link>
                 </div>
                   <div className="col-md-12 newsCount" data-month="2">
                       <Link to="/" target="_blank">
                      <div className="inr-news-blk">
                         <h6 className="date">DD MM YYYY</h6>
                         <div className="inr-news-content">
                            <h3>News Heading</h3>
                            <p>Stay connected for more updates!</p>
                            <h6 className="loc"> 
                               <span> View Attachment <i className="bi bi-arrow-right"></i> </span>
                            </h6>
                         </div>
                      </div>
                  </Link>
                 </div>
                     <div className="col-md-12 newsCount" data-month="1">
                       <Link to="/" target="_blank">
                      <div className="inr-news-blk">
                         <h6 className="date">DD MM YYYY</h6>
                         <div className="inr-news-content">
                            <h3>News Heading</h3>
                            <p>Stay connected for more updates!</p>
                            <h6 className="loc"> 
                               <span> View Attachment <i className="bi bi-arrow-right"></i> </span>
                            </h6>
                         </div>
                      </div>
                  </Link>
                 </div> 
    
                    <div className="load-more"><div className="next">Show More</div></div>
                </div> */}
               </div>
               </div>
         </section>
      </>
   )
}

export default News
import React from 'react'
import { Link } from 'react-router-dom'
const AllRoundExcellence = () => {
  return (
    <>
    <section className="page-header">
        <div className="page-header-bg"></div>
        <div className="page-header-shape-1"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/page-header-shape-1.png" alt="Loreto Convent School, Delhi Cantt"/></div>
        <div className="container">
        <div className="page-header__inner">
            <ul className="thm-breadcrumb list-unstyled ml-0">
                <li><Link to="/"> Home</Link></li>
                <li> Achievements </li>
                <li> All Round Excellence  </li>
            </ul>
            <h2>All Round Excellence </h2>
        </div>
        </div>
    </section>
    <section className="innerbody">
   <div className="container">
      <div className="row">
         <div className="col-md-12">
             <div className="accordion achievementacc" id="accordionExample">
                
                <div class="accordion-item">
                  <div className="card">
                  <div className="card-header">
                       <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">

                       2016-17
                       </button>
                     </div>
                  <div id="collapseOne" className="collapse show" data-bs-parent="#accordionExample">
                     <div className="card-body">
                        <table className="table table-striped table-bordered">
                           <tbody>
                              <tr >
                                 <th><b>CLASS </b></th>
                                 <th><b>STUDENT NAME</b> </th>
                              </tr>
                              <tr>
                                 <td>CLASS V    A</td>
                                 <td>AVIKA    KUMAR<br/>
                                    VIGYA    VINOY<br/>
                                    NAVLIKA    BHARDWAJ
                                 </td>
                              </tr>
                              <tr>
                                 <td>CLASS V    B</td>
                                 <td>ANUSHRUTI    RAWAT<br/>
                                    GRACE    MARIA FRANCIS<br/>
                                    SANCHARI    CHATTERJEE
                                 </td>
                              </tr>
                              <tr>
                                 <td>CLASS V    C</td>
                                 <td>ADRIJA    MITRA<br/>
                                    AANSHI    DAGAR<br/>
                                    AADYA    SRIVASTAVA
                                 </td>
                              </tr>
                              <tr>
                                 <td>CLASS VI    A</td>
                                 <td>SIDDHI    GUPTA<br/>
                                    ANSHIKA    PATRA<br/>
                                    RIYA    GULIA
                                 </td>
                              </tr>
                              <tr>
                                 <td>CLASS VI    B</td>
                                 <td>ALICIA    ELIZABETH JOHN<br/>
                                    ABHITHA    NANDY<br/>
                                    PRATISHTHA
                                 </td>
                              </tr>
                              <tr>
                                 <td>CLASS VI    C</td>
                                 <td>VAISHANAVI    RAWAT<br/>
                                    RASHI    PANDEY<br/>
                                    PAAKHI    TIWARI
                                 </td>
                              </tr>
                              <tr>
                                 <td>CLASS    VII A</td>
                                 <td>AMMAIRA    KAPUR<br/>
                                    ISHITA    BHATT
                                 </td>
                              </tr>
                              <tr>
                                 <td>CLASS    VII B</td>
                                 <td>TAVEESHA    KEPRATE<br/>
                                    KANAN    SHEORAN<br/>
                                    AASHI    SINGH
                                 </td>
                              </tr>
                              <tr>
                                 <td>CLASS    VII C</td>
                                 <td>ANMOL    RAMAN<br/>
                                    AMRIT    KAUR<br/>
                                    NAVYA PANDEY
                                 </td>
                              </tr>
                              <tr>
                                 <td>CLASS    VIII A</td>
                                 <td>KAMAKSHI    BHANDARI<br/>
                                    C.    VAISHANAVI
                                 </td>
                              </tr>
                              <tr>
                                 <td>CLASS    VIII B</td>
                                 <td>ANUSHA    KAIRI<br/>
                                    ANANYA    SINHA<br/>
                                    RAIMA    ANN JOSPEH
                                 </td>
                              </tr>
                              <tr>
                                 <td>CLASS    VIII C</td>
                                 <td>AARUSHI    SACHDEVA<br/>
                                    ANUSHKA    VASHISHT<br/>
                                    OJAL    MIJAR
                                 </td>
                              </tr>
                              <tr>
                                 <td>CLASS IX    A</td>
                                 <td>PARUL    MANN<br/>
                                    RIA JAIN<br/>
                                    TAMANNA    SWAIN
                                 </td>
                              </tr>
                              <tr>
                                 <td>CLASS IX    B</td>
                                 <td>KASHVI    SINGH<br/>
                                    KHUSHI MALHOTRA<br/>
                                    NANDINI    BHUSRI
                                 </td>
                              </tr>
                              <tr>
                                 <td>CLASS IX    C</td>
                                 <td>REMYA    SAJI</td>
                              </tr>
                              <tr>
                                 <td>CLASS XI    A</td>
                                 <td>MANSI    RAI</td>
                              </tr>
                              <tr>
                                 <td>CLASS XI    B</td>
                                 <td>ISHITA    TALWAR<br/>
                                    KHYATI    BATRA
                                 </td>
                              </tr>
                              <tr>
                                 <td>CLASS XI    C</td>
                                 <td>KANIKA    MITHARWAL</td>
                              </tr>
                           </tbody>
                        </table>
                     </div>
                  </div>
               </div>
               </div>
               <div class="accordion-item">
                  <div className="card">
                  <div className="card-header">
                     


                           <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
       2014-15
      </button>
                  


                  </div>
                  <div id="collapseTwo" className="collapse" data-bs-parent="#accordionExample">
                     <div className="card-body">
                        <table className="table table-striped table-bordered">
                           <tbody>
                              <tr >
                                 <th><b>CLASS </b></th>
                                 <th><b>STUDENT NAME</b> </th>
                              </tr>
                              <tr>
                                 <td>CLASS V-A </td>
                                 <td>AASHI SINGH<br/>
                                    AADYA 
                                 </td>
                              </tr>
                              <tr>
                                 <td>CLASS V-B </td>
                                 <td>ANANYA SINGH<br/>
                                    ANNU HOODA<br/>
                                    ROHI ARADHNA MEAGHAR 
                                 </td>
                              </tr>
                              <tr>
                                 <td>CLASS V- C </td>
                                 <td>AMMAIRA KAPUR<br/>
                                    MANIDIPA SARDAR<br/>
                                    KANAN SHEORAN 
                                 </td>
                              </tr>
                              <tr>
                                 <td>CLASS VI-A</td>
                                 <td>SREESHMA N. NAIR<br/>
                                    SHREYA NIMBALI<br/>
                                    KAMAKSHI BHANDARI 
                                 </td>
                              </tr>
                              <tr>
                                 <td>CLASS VI-B </td>
                                 <td>ESHITA KHANNA
                                    <br/>
                                    URVI SAXENA<br/>
                                    LISA DE SOUZA 
                                 </td>
                              </tr>
                              <tr>
                                 <td>CLASS VI-C </td>
                                 <td>SHAANYA SINGH<br/>
                                    ANANYA SINGH<br/>
                                    NRIPA NAMBIAR 
                                 </td>
                              </tr>
                              <tr>
                                 <td>CLASS VII-A </td>
                                 <td>KASHISH SINGH<br/>
                                    REMYA SAJI KANDAHINKA<br/>
                                    HUNNY DAHIYA 
                                 </td>
                              </tr>
                              <tr>
                                 <td>CLASS VII-B </td>
                                 <td>TARANGINI BHALLA<br/>
                                    TAMANNA SIVAIN<br/>
                                    OINDRILLA GHOSH 
                                 </td>
                              </tr>
                              <tr>
                                 <td>CLASS VII-C </td>
                                 <td>RIYA JAIN
                                    <br/>
                                    ISHIKA SRIVASTAVA<br/>
                                    KHUSHI MALHOTRA 
                                 </td>
                              </tr>
                              <tr>
                                 <td>CLASS VIII-A </td>
                                 <td>KASHISH KAUSHIK<br/>
                                    RIYA ELIZABETH JOHN<br/>
                                 </td>
                              </tr>
                              <tr>
                                 <td>CLASS VIII-B </td>
                                 <td>ISHIKA JOSHI<br/>
                                    DEEPALI GANDOTRA<br/>
                                    ANINYA SETHI 
                                 </td>
                              </tr>
                              <tr>
                                 <td>CLASS VIII-C </td>
                                 <td>RIJUL VERMA<br/>
                                    BULBUL BHATTI. 
                                 </td>
                              </tr>
                           </tbody>
                        </table>
                     </div>
                  </div>
               </div>
               </div>
               <div class="accordion-item">
                  <div className="card">
                  <div className="card-header">

                         <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
       2013-14
      </button>
                  </div>
                  <div id="collapseThree" className="collapse" data-bs-parent="#accordionExample">
                     <div className="card-body">
                        <table className="table table-striped table-bordered">
                           <tbody>
                              <tr >
                                 <th>
                                    <b>CLASS </b>
                                 </th>
                                 <th>
                                    <b>STUDENT NAME</b>
                                 </th>
                              </tr>
                              <tr >
                                 <td>12B
                                 </td>
                                 <td>DISHA DAS<br/>
                                    ADITI BISHNOI
                                 </td>
                              </tr>
                              <tr >
                                 <td>12A
                                 </td>
                                 <td>VRINDA CHAWLA<br/>
                                    DHARAMJOT KAUR 
                                 </td>
                              </tr>
                              <tr >
                                 <td>11B
                                 </td>
                                 <td>PRATISHTHA MAMGAIN<br/>
                                    VARSHA SURESH<br/>
                                    SNEHA SINGH
                                 </td>
                              </tr>
                              <tr >
                                 <td>11A 
                                 </td>
                                 <td>RUCHIKA ADLAKHA<br/>
                                    TANYA KALRA<br/>
                                    SAYESHA BERERA
                                 </td>
                              </tr>
                              <tr >
                                 <td>9C
                                 </td>
                                 <td>ANANYA AGRARWAL<br/>
                                    ANUSHA SINHA<br/>
                                    SHAONI KAR
                                 </td>
                              </tr>
                              <tr >
                                 <td>9B
                                 </td>
                                 <td>SANCHITA SIVARAMAN<br/>
                                    SHANVI KUKRRLI<br/>
                                    SIMRAN BANGA
                                 </td>
                              </tr>
                              <tr >
                                 <td>9A
                                 </td>
                                 <td>SANSKRITI SHARMA<br/>
                                    MALLIKA SINGLA<br/>
                                    AADYA MAGOTRA 
                                 </td>
                              </tr>
                              <tr >
                                 <td>8C
                                 </td>
                                 <td>DIVYA KUMAR<br/>
                                    NIHARIKA SHANKAR<br/>
                                    VANSHIKA SAWHNEY<br/>
                                    SHREEYA SINGH
                                 </td>
                              </tr>
                              <tr >
                                 <td>8B
                                 </td>
                                 <td>FIZA JAGGI
                                    <br/>
                                    MUSKAN SEHGAL<br/>
                                    VIDUSHI SOOD<br/>
                                    PRATISHTA DIWAKAR<br/>
                                    MEENAKSHI P.
                                 </td>
                              </tr>
                              <tr >
                                 <td>8A
                                 </td>
                                 <td>RUSHALI SHIVPRASADE
                                 </td>
                              </tr>
                              <tr >
                                 <td>7C
                                 </td>
                                 <td>TANYA GUPTA<br/>
                                    ZOYA JAFFERY
                                 </td>
                              </tr>
                              <tr >
                                 <td>7B
                                 </td>
                                 <td>NAVNEETA SHANKAR<br/>
                                    NIKITA SHARMA
                                 </td>
                              </tr>
                              <tr >
                                 <td>7A
                                 </td>
                                 <td>KUHIKAA VAISHNAVEE ARORA<br/>
                                    SUKRITI MACKER<br/>
                                    AARUSHI RAY
                                 </td>
                              </tr>
                              <tr >
                                 <td>6C
                                 </td>
                                 <td>NISHTHA THAKUR<br/>
                                    VRINDA DABAS<br/>
                                    SHAMBHAVI SINGH
                                 </td>
                              </tr>
                              <tr >
                                 <td>6B
                                 </td>
                                 <td>MANCHIT JASROTIA<br/>
                                    LYDIA N. HAOKIP<br/>
                                    RITU JACOB
                                 </td>
                              </tr>
                              <tr >
                                 <td>6A 
                                 </td>
                                 <td>NISHTHA GUPTA<br/>
                                    PRITHVI KRISHNA
                                 </td>
                              </tr>
                              <tr >
                                 <td>5C
                                 </td>
                                 <td>KASHISH KAUSHIK<br/>
                                    EMOME THINGUJAM
                                 </td>
                              </tr>
                              <tr >
                                 <td>5B 
                                 </td>
                                 <td>DISHA ROY<br/>
                                    VAISHNAVI PANDE<br/>
                                    DIYA CHAUDHURI
                                 </td>
                              </tr>
                              <tr >
                                 <td>5A
                                 </td>
                                 <td>DEEPALI GANDOTRA<br/>
                                    ISHIKA JOSHI
                                 </td>
                              </tr>
                           </tbody>
                        </table>
                     </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</section>
   </>
  )
}

export default AllRoundExcellence
import React from 'react'
import { Link } from 'react-router-dom'
const CompanionMaryWard = () => {
  return (
    <>
    <section className="page-header">
        <div className="page-header-bg"></div>
        <div className="page-header-shape-1"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/page-header-shape-1.png" alt="Loreto Convent School, Delhi Cantt"/></div>
        <div className="container">
        <div className="page-header__inner">
            <ul className="thm-breadcrumb list-unstyled ml-0">
                <li><Link to="/"> Home</Link></li>
                <li> Founder </li>
                <li>The Companions of Mary Ward</li>
            </ul>
            <h2>The Companions of Mary Ward </h2>
        </div>
        </div>
    </section>
    <section className="innerbody">
<div className="container"> 
    <div className="row"> 
            <div className="col-md-12 mt-4"> 

<p><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/companion-mary-ward.jpg"  className="img-fluid right-align-images" alt="Loreto Convent School, Delhi Cantt"/>
    The Painted Life, a series of 50 images, is believed to have been commissioned by Mary Ward's initial companions to narrate her life story. The positioning of figures in this painting—Mary Poyntz, Joanna Brown, Catherine Smith, Susanna Rookwood, Winifred Wigmore, and Mary Ward—from left to right, provides insights into Mary's leadership style. Contrary to traditional hierarchical approaches, Mary is not placed as the central figure; she appears as an equal, a collaborator, and a co-worker. The 'open circle' formation emphasizes dialogue, shared power, mutuality, and friendship.
</p>
        <p>The hands and gazes of the depicted women reveal their unique personalities, offering a glimpse into the individuality of each. Despite these differences, their overlapping bodies signify shared humanity. Notably, these women, responding to needs across the Western world, seldom congregated in the same place.</p>
        <p>Mary Ward's leadership, shared with her companions, was characterized by exemplary communication, trust, belief in emerging leaders, and a deep conviction that their mission was a divine call to shape a more just and joyful world.</p>
        <p><b>Mary Poyntz,</b> one of Mary Ward’s earliest companions, accompanied her to England, witnessed her death near York, and later founded a new community in Paris. She eventually became the third chief superior of the Institute.
</p>
        <p><b>Joanna Brown,</b> the oldest among Mary’s initial companions at 28, served the foundation with quiet dedication. Her role was that of enduring the challenges without seeking acclaim, rewarded instead with esteem and affection.</p>
        <p><b>Catherine Smith,</b>  though less known, was described as a woman of courage and no common merit. She served in St. Omer and Liege until 1631 and was devoted to Mary Ward, possibly being among those urging the founding of a religious society.</p>
        <p><b>Susannah Rookwood</b>  played a significant role in the Institute's early development. Living between London and continental Europe, she crossed the Alps on foot with Mary Ward, served as a superior in Naples, and passed away suddenly in 1624 at the age of 41.</p>
        <p><b>Barbara Babthorpe</b>  joined Mary Ward and her companions in 1609, eventually becoming Mary's chosen successor as Chief Superior. She passed away in Rome in 1654, buried at the English College.</p>
</div>
</div> 
</div>
</section>
   </>
  )
}

export default CompanionMaryWard
import React from 'react'
import { Link } from 'react-router-dom'
const VacationLeave = () => {
  return (
    <>
    <section className="page-header">
        <div className="page-header-bg"></div>
        <div className="page-header-shape-1"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/page-header-shape-1.png" alt="Loreto Convent School, Delhi Cantt"/></div>
        <div className="container">
        <div className="page-header__inner">
            <ul className="thm-breadcrumb list-unstyled ml-0">
                <li><Link to="/"> Home</Link></li>
                <li> Prospectus </li>
                <li> Vacation Leave  </li>
            </ul>
            <h2>Vacation Leave </h2>
        </div>
        </div>
    </section>
    <section className="innerbody">
<div className="container">
<div className="row">
<div className="col-md-12">
<p><b>Regularity is to be maintained in attendance and work:</b>
</p>
<p>Although a minimum attendance of 75% each year preceding one month from final examinations is compulsory as directed by C B S E, the school mandates 90% each year, preceding one month from final examination.
</p>
<p>All leave is to be sanctioned only by the Principal and must be recorded in this diary. Leave must be sanctioned before it is taken.</p>
<ul className="lilist">
<li>A list of holidays for the current year is printed in this diary. All are expected to attend school on the re-opening day after each vacation. Parents are requested not to take their children on vacation before the set date nor to extend these vacations. Students should be present on the opening and closing date of each term. Pupils not attending punctually after holidays are liable to have their names struck off the rolls.
If they are re-admitted they must pay the Admission fees again.

</li><li>No leave of absence will be given without a previously written application from parents to the Principal.
</li><li>Medical appointments should be made on the weekly holiday. In case the appointment has to be made on a school day, the pupil should remain absent for the full day, with prior permission.
</li><li>Prior permission is required for any planned leave and must be taken from the Principal only and before reserving tickets in case of a journey . No leave of absence will be given without a previously written application from the parents. If leave is required for more than 3 days the parent must meet the Principal during her office hours and get permission.
</li><li>Parents must enter the Regularity Record in the diary of their child stating briefly the cause of the absence or delay, if she has been absent from class for some unforeseen reason. (Reasons of a private nature may also be submitted in a letter).Those absent for more than 3 days due to medical reasons, must provide the medical papers on the day of return.
</li><li>If a pupil needs to leave school during class hours for any emergency in the family, special permission is granted only if a written request from parents is submitted to the Principal or the co-ordinators in her absence.
</li><li>Leave from school must not be prefixed or suffixed to school holidays.
</li><li>A sick child should not be sent to school even though there may be a test.
</li><li>All are expected to be in school by the first bell which rings at 7:05 am in Summer and 7:35 am in Winter. Disciplinary action will be taken against frequent offenders. Those who are late 3 times will be detained after school, those who are late 6 times shall be suspended from school.
</li><li>The school authorities are not responsible for pupils who are not called for at the time of dismissal.</li>
<li>Students who remain absent without prior sanction for TWO consecutive school weeks shall be off the rolls without explanation. They can be readmitted only after paying all admission charges, provided there 
is a vacancy.</li>

<li><b>Attendance is compulsory</b> on certain days of the Academic year: Absenteeism on compulsory attendance days will be dealt with sternly. <b>Parents will need to meet the Principal personally with a written
 explanation for absence to be condoned and for the child to rejoin her class. In case of sickness, the parent must produce the child's doctor’s certificate & prescription. These days are: -</b>
</li>
<li>a)  Non-teaching days like Annual Function, Teachers’ Day, Children’s Day, Class outings, etc.</li>
<li>b)  Two weeks before every major examination</li>
<li>c)  A school working day amidst several public holidays.</li>
<li>d)  All must attend school on the last day before vacation and on the re-opening day after the vacation.</li>




<li>No pupil may leave the school premises without the permission of the Principal / Co-ordinators and the required Gate Pass.
</li><li>For the withdrawal of a pupil from the School, a calendar month’s notice must be given in writing, otherwise a calendar month’s fee will be charged in lieu of notice. Withdrawals in December and after require the last quarter fees to be paid.
</li><li>Transfer certificate can be obtained only after all dues have been paid. Duplicate copies of Transfer Certificate are ordinarily not issued. In special cases such certificates will be issued after verification, on payment of Rs.100/-.
</li><li>A duplicate copy of the report card can be obtained on payment of Rs.100/-.</li>

</ul>
</div>
</div>
</div>
</section>
   </>
  )
}

export default VacationLeave
import React from 'react'
import { Link } from 'react-router-dom'
const SchoolMotto = () => {
  return (
    <>
    <section className="page-header">
        <div className="page-header-bg"></div>
        <div className="page-header-shape-1"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/page-header-shape-1.png" alt="Loreto Convent School, Delhi Cantt"/></div>
        <div className="container">
        <div className="page-header__inner">
            <ul className="thm-breadcrumb list-unstyled ml-0">
                <li><Link to="/"> Home</Link></li>
                <li> About Us</li>
                <li> School Motto  </li>
            </ul>
            <h2>School Motto </h2>
        </div>
        </div>
    </section>
    <section className="innerbody">
<div className="container">

<div className="col-md-12">
<h4 className="h4">The School Crest</h4>
<img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/moto.jpg"  className="img-fluid mx-auto d-block" alt="Loreto Convent School Delhi Cantt"/>
<br />
<p className="text-center">This crest is familiar to Loreto Pupils worldwide.<br />
It is surmounted by the words<br />
Maria Regina Angelorum<br />

which indicate the patronage of<br />
Our Lady, Queen of Angels.<br />
The emblem is rich in symbolism:<br />
The Cross – a symbol of self sacrificing love<br />
The Heart with a Cross – Source of infinite love<br />
The Heart with a Sword – Our human model and inspiration;<br />
The Anchor – A symbol of hope:<br />
God’s love for us is the anchor of our lives.<br />
This symbolism is summed up in the final scroll:<br />
Cruci dum spiro fido:<br />
As I breathe, I place my trust in God
</p>
</div>
<br />
<div className="col-md-12">


<h2 className="text-center">SCHOOL MOTTO:</h2>
<p className="text-center"><i><b>Receive that you may give</b> </i></p>
<p className="text-center"><i><b>Jesus said:</b> “Let the little children come to me, and do not stop them, for it is to such as these that the Kingdom of Heaven 
belongs.”</i></p>
<p className="text-center">Loreto Convent, Delhi Cantt. attends the values of INTEGRITY, JUSTICE, FREEDOM AND LOVE as essential elements in its educational endeavour to help students become mature, reliable people of character, to be firm on principles and 
courageous in action. Therefore, parents, who intend to have their children educated here would need to be convinced of the importance of these values and be prepared to make the necessary sacrifices to live by these. </p>

</div>

</div>
</section>
   </>
  )
}

export default SchoolMotto
import { useEffect, useState } from "react";
import React from 'react'
import { useNavigate, Link } from "react-router-dom"
import { getGalleryInner } from "../Service/Api";
const Achievements = () => {
    const navigate = useNavigate()
    const [data, setData] = useState([]);
    const [filterData, setFilteredData] = useState([]);
    const [selectedYear, setSelectedYear] = useState("2024");
    const [selectedMonth, setSelectedMonth] = useState("All");
    const [monthOptions, setMonthOptions] = useState(["All"]);
    const [yearRanges, setYearRanges] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
   
    
    useEffect(() => {
        const fetchData = async () => {
            const galleryData = await getGalleryInner(); 
            setData(galleryData);
            const uniqueYears = Array.from(
                new Set(galleryData.flatMap((item) => {
                    if (item.date) {
                        const year = new Date(item.date).getFullYear();
                        return [year];
                    } else {
                        return [];
                    }
                }))
            );
            const uniqueYearRanges = uniqueYears.map(year => `${year}`);
            uniqueYearRanges.sort((a, b) => b - a);
            setYearRanges(uniqueYearRanges);
  
            const uniquemonth = Array.from(
                new Set(galleryData.flatMap((item) => {
                    if (item.date) {
                        const month = new Date(item.date).getMonth() + 1;
                        return [month];
                    } else {
                        return [];
                    }
                }))
            );
            const uniqueMonths = uniquemonth.map(month => `${month}`);
            uniqueMonths.sort((a, b) => b - a);
            setMonthOptions(uniqueMonths);
        };
        fetchData();
    }, []);
  
    useEffect(() => {
        let filteredData = data;
        if (selectedYear !== "All") {
            filteredData = filteredData.filter((item) => {
                if (item.date && item.date.trim() !== "") {
                    const year = new Date(item.date).getFullYear();
                    const month = new Date(item.date).getMonth() + 1;
                    return parseInt(selectedYear) === year && (selectedMonth === "All" || parseInt(selectedMonth) === month);
                } else {
                    return false;
                }
            });
        }
        if (selectedMonth !== "All") {
            filteredData = filteredData.filter((item) => {
                if (item.date && item.date.trim() !== "") {
                    const month = new Date(item.date).getMonth() + 1;
                    return parseInt(selectedMonth) === month;
                } else {
                    return false;
                }
            });
        }
        if (searchQuery) {
            filteredData = filteredData.filter((item) =>
                item.title.toLowerCase().includes(searchQuery.toLowerCase())
            );
        }
        filteredData.sort((a, b) => new Date(b.date) - new Date(a.date));
        setFilteredData(filteredData);
    }, [selectedYear, selectedMonth, searchQuery, data]);
    const handleModal = (id) => {
      navigate(`/SubGallery?id=${id}`);
    };
    const emptyArray = [
      // { description: "“Educating the mind without educating the heart is no education at all.” Sacred Heart has been educating boys and girls since 1990; though much has changed over the years our mission remains the same. Sacred Heart is committed to developing students of high academic achievement, intellectual curiosity, and strong moral character. As a result, year after year Sacred Heart sends our respectful, responsible and compassionate young adults, who are well prepared to tackle the challenges that lay ahead. Since arriving at Sacred Heart in 2016, I have had the opportunity to observe this unique institution up close. I have seen its commitment to academic excellence, its dynamic student body, and incredibly committed faculty and a loving community where the students and families are proud and supportive of one another. As I walk down the hallways of the school every day, I can hear the chatter of eager minds; the shouts of excitement from the victorious athletes, the thumb of dancers feed and the sound of melodious voices harmonizing the perpetual energy, movement and enthusiasm permeate the atmosphere. Ours is a school with difference. We value creativity and innovation and strive to nurture them in our students. Sacred Heartians are groomed to become confident, articulate and enlightened young citizens ready to step into the global society with dignity, honour and integrity. Aristotle once said “Educating the mind without educating the heart is no education at all”. Even as we impart education to match the advancement in technology and globalization, we march our children ahead with Sacred Heart ethos of moral values and principles. We Endeavour constantly to instill these qualities in our children. We pride ourselves to help them grow and develop into sensitive, responsible God loving citizens of the future. May the Sacred Heart of Jesus bless us abundantly in all our endeavourers.", attachments: "director.jpg" },   
    ]
  return (
    <>
    <section className="page-header">
        <div className="page-header-bg"></div>
        <div className="page-header-shape-1"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/page-header-shape-1.png" alt="Loreto Convent School, Delhi Cantt"/></div>
        <div className="container">
        <div className="page-header__inner">
            <ul className="thm-breadcrumb list-unstyled ml-0">
                <li><Link to="/"> Home</Link></li>
                <li> Achievements </li>
                <li> Achievements  </li>
            </ul>
            <h2>Achievements </h2>
        </div>
        </div>
    </section>
    <section className="innerbody">
<div className="container">
                <div className="row tabs-dynamic">
                      <div className="col-xxl-3 col-xl-3 col-lg-6 col-12">
                        <div className="count-val">
                        <p>Total Categories:  {filterData.length} </p>
                        </div>
                    </div>
                    <div className="col-xxl-3 col-xl-3 col-lg-6 col-12">
                        <div className="month-selection">
                          
                        <select  
                                 value={selectedYear}
                                 onChange={(e) => setSelectedYear(e.target.value)}
                                 className="yearSelection">
                                 <option value={"All"}>All</option>
                                 {yearRanges.map((yr) => (
                                    <option key={yr} value={yr}>{yr}</option>
                                 ))}
                              </select>
                        </div>
                    </div>
                     <div className="col-xxl-3 col-xl-3 col-lg-6 col-12">
                        <div className="month-selection">
                        <select
                        value={selectedMonth}
                        onChange={(e) => setSelectedMonth(e.target.value)}
                        className="monthSelect"
                     ><option value={"All"}>All</option>
                        {monthOptions.map((month, index) => (
                           <option key={index} value={month}>{month}</option>
                        ))}
                     </select>
                        </div>
                    </div>
                     
                   <div className="col-xxl-3 col-xl-3 col-lg-6 col-12">
                        <div className="searchBox">
                        <input
                    type="text"
                    id="myInput"
                    name="name"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    placeholder="Search Here.."
                />
                        </div>
                    </div>
                </div>
                <div className="row years year2023 showYear"> 
                {filterData?.length > 0 ? filterData?.filter((div) => div.category === "Achivements").map((item, index) => (
            <div className="col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-12 galleryCount" key={index}>
                        <div className="gal_new">
                                    <article>
                                       <img src={`https://d280nq1n4mqyso.cloudfront.net/api/image/${item?.attachments[0]}`}  alt="Loreto Convent School Delhi Cantt" className="img-fluid"/>
                                       <div className="gal-content">
                                          <h4 className="catgry-name">{item.title}</h4>
                                          <p><strong>Description: </strong>{item.description}</p>
                                          <h6 className="date"><i className="bi bi-calendar-week"></i>  {item.date}                                             
                                              <span> <a onClick={() => navigate(`/AchievementDetails?id=${item._id}`)}>View Album  <i className="bi bi-arrow-right"></i></a></span>
                                          </h6>
                                          <div className="total-img"><i className="bi bi-card-image"></i><p>{item?.attachments.length}</p></div>
                                       </div>
                                    </article>
                                </div>
                         </div>
)):emptyArray.map((data, index) => ( <div className="col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-12 galleryCount" >
<div className="gal_new">
            <article>
               <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/gal-1.jpg"  alt="Loreto Convent School Delhi Cantt" className="img-fluid"/>
               <div className="gal-content">
                  <h4 className="catgry-name">Category Name</h4>
                  <p><strong>Description: </strong>Stay Tuned for Latest Updates</p>
                  <h6 className="date"><i className="bi bi-calendar-week"></i> DD MM YYYY                                                 
                      <span>  <Link to="/SubCategoryKindergarten">View Album  <i className="bi bi-arrow-right"></i></Link></span>
                  </h6>
                  <div className="total-img"><i className="bi bi-card-image"></i><p>5</p></div>
               </div>
            </article>
        </div>
 </div>
))}

                          {/* <div className="col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-12 galleryCount" data-month="1">
                        <div className="gal_new">
                                    <article>
                                       <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/gal-1.jpg"  alt="Loreto Convent School Delhi Cantt" className="img-fluid"/>
                                       <div className="gal-content">
                                          <h4 className="catgry-name">Category Name</h4>
                                          <p><strong>Description: </strong>Stay Tuned for Latest Updates</p>
                                          <h6 className="date"><i className="bi bi-calendar-week"></i> DD MM YYYY                                                 
                                              <span>  <Link to="/SubCategoryKindergarten">View Album  <i className="bi bi-arrow-right"></i></Link></span>
                                          </h6>
                                          <div className="total-img"><i className="bi bi-card-image"></i><p>5</p></div>
                                       </div>
                                    </article>
                                </div>
                         </div>

                               <div className="col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-12 galleryCount" data-month="2">
                        <div className="gal_new">
                                    <article>
                                       <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/gal-1.jpg"  alt="Loreto Convent School Delhi Cantt" className="img-fluid"/>
                                       <div className="gal-content">
                                          <h4 className="catgry-name">Category Name</h4>
                                          <p><strong>Description: </strong>Stay Tuned for Latest Updates</p>
                                          <h6 className="date"><i className="bi bi-calendar-week"></i> DD MM YYYY                                                 
                                              <span>  <Link to="/SubCategoryKindergarten">View Album  <i className="bi bi-arrow-right"></i></Link></span>
                                          </h6>
                                          <div className="total-img"><i className="bi bi-card-image"></i><p>5</p></div>
                                       </div>
                                    </article>
                                </div>
                         </div>
                                    <div className="col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-12 galleryCount" data-month="2">
                        <div className="gal_new">
                                    <article>
                                       <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/gal-1.jpg"  alt="Loreto Convent School Delhi Cantt" className="img-fluid"/>
                                       <div className="gal-content">
                                          <h4 className="catgry-name">Category Name</h4>
                                          <p><strong>Description: </strong>Stay Tuned for Latest Updates</p>
                                          <h6 className="date"><i className="bi bi-calendar-week"></i> DD MM YYYY                                                 
                                              <span>  <Link to="/SubCategoryKindergarten">View Album  <i className="bi bi-arrow-right"></i></Link></span>
                                          </h6>
                                          <div className="total-img"><i className="bi bi-card-image"></i><p>5</p></div>
                                       </div>
                                    </article>
                                </div>
                         </div>
                                    <div className="col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-12 galleryCount" data-month="2">
                        <div className="gal_new">
                                    <article>
                                       <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/gal-1.jpg"  alt="Loreto Convent School Delhi Cantt" className="img-fluid"/>
                                       <div className="gal-content">
                                          <h4 className="catgry-name">Category Name</h4>
                                          <p><strong>Description: </strong>Stay Tuned for Latest Updates</p>
                                          <h6 className="date"><i className="bi bi-calendar-week"></i> DD MM YYYY                                                 
                                              <span>  <Link to="/SubCategoryKindergarten">View Album  <i className="bi bi-arrow-right"></i></Link></span>
                                          </h6>
                                          <div className="total-img"><i className="bi bi-card-image"></i><p>5</p></div>
                                       </div>
                                    </article>
                                </div>
                         </div>
                                    <div className="col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-12 galleryCount" data-month="2">
                        <div className="gal_new">
                                    <article>
                                       <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/gal-1.jpg"  alt="Loreto Convent School Delhi Cantt" className="img-fluid"/>
                                       <div className="gal-content">
                                          <h4 className="catgry-name">Category Name</h4>
                                          <p><strong>Description: </strong>Stay Tuned for Latest Updates</p>
                                          <h6 className="date"><i className="bi bi-calendar-week"></i> DD MM YYYY                                                 
                                              <span> <Link to="/SubCategoryKindergarten">View Album  <i className="bi bi-arrow-right"></i></Link></span>
                                          </h6>
                                          <div className="total-img"><i className="bi bi-card-image"></i><p>5</p></div>
                                       </div>
                                    </article>
                                </div>
                         </div>   */}

 {/* <div className="load-more">
                        <div className="next">Show More</div>
                    </div> */}
                </div>
                {/* <div className="row years year2022">
                               <div className="col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-12 galleryCount" data-month="1">
                        <div className="gal_new">
                                    <article>
                                       <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/gal-1.jpg"  alt="Loreto Convent School Delhi Cantt" className="img-fluid"/>
                                       <div className="gal-content">
                                          <h4 className="catgry-name">Category Name</h4>
                                          <p><strong>Description: </strong>Stay Tuned for Latest Updates</p>
                                          <h6 className="date"><i className="bi bi-calendar-week"></i> DD MM YYYY                                                 
                                              <span> <Link to="/SubCategoryKindergarten">View Album  <i className="bi bi-arrow-right"></i></Link></span>
                                          </h6>
                                          <div className="total-img"><i className="bi bi-card-image"></i><p>5</p></div>
                                       </div>
                                    </article>
                                </div>
                         </div>
                               <div className="col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-12 galleryCount" data-month="2">
                        <div className="gal_new">
                                    <article>
                                       <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/gal-1.jpg"  alt="Loreto Convent School Delhi Cantt" className="img-fluid"/>
                                       <div className="gal-content">
                                          <h4 className="catgry-name">Category Name</h4>
                                          <p><strong>Description: </strong>Stay Tuned for Latest Updates</p>
                                          <h6 className="date"><i className="bi bi-calendar-week"></i> DD MM YYYY                                                 
                                              <span> <Link to="/SubCategoryKindergarten">View Album  <i className="bi bi-arrow-right"></i></Link></span>
                                          </h6>
                                          <div className="total-img"><i className="bi bi-card-image"></i><p>5</p></div>
                                       </div>
                                    </article>
                                </div>
                         </div>
                                    <div className="col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-12 galleryCount" data-month="2">
                        <div className="gal_new">
                                    <article>
                                       <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/gal-1.jpg"  alt="Loreto Convent School Delhi Cantt" className="img-fluid"/>
                                       <div className="gal-content">
                                          <h4 className="catgry-name">Category Name</h4>
                                          <p><strong>Description: </strong>Stay Tuned for Latest Updates</p>
                                          <h6 className="date"><i className="bi bi-calendar-week"></i> DD MM YYYY                                                 
                                              <span> <Link to="/SubCategoryKindergarten">View Album  <i className="bi bi-arrow-right"></i></Link></span>
                                          </h6>
                                          <div className="total-img"><i className="bi bi-card-image"></i><p>5</p></div>
                                       </div>
                                    </article>
                                </div>
                         </div>
                
                      <div className="col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-12 galleryCount" data-month="1">
                        <div className="gal_new">
                                    <article>
                                       <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/gal-1.jpg"  alt="Loreto Convent School Delhi Cantt" className="img-fluid"/>
                                       <div className="gal-content">
                                          <h4 className="catgry-name">Category Name</h4>
                                          <p><strong>Description: </strong>Stay Tuned for Latest Updates</p>
                                          <h6 className="date"><i className="bi bi-calendar-week"></i> DD MM YYYY                                                 
                                              <span> <Link to="/SubCategoryKindergarten">View Album  <i className="bi bi-arrow-right"></i></Link></span>
                                          </h6>
                                          <div className="total-img"><i className="bi bi-card-image"></i><p>5</p></div>
                                       </div>
                                    </article>
                                </div>
                         </div>
                    <div className="load-more">
                        <div className="next">Show More</div>
                    </div>
                </div>  */}
            </div>

</section>
   </>
  )
}

export default Achievements
import React from 'react'
import { Link } from 'react-router-dom'
const HowtoLearn = () => {
  return (
    <>
    <section className="page-header">
        <div className="page-header-bg"></div>
        <div className="page-header-shape-1"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/page-header-shape-1.png" alt="Loreto Convent School, Delhi Cantt"/></div>
        <div className="container">
        <div className="page-header__inner">
            <ul className="thm-breadcrumb list-unstyled ml-0">
                <li><Link to="/"> Home</Link></li>
                <li> Prospectus </li>
                <li> How to Learn  </li>
            </ul>
            <h2>How to Learn</h2>
        </div>
        </div>
    </section>
    <section className="innerbody">
<div className="container">
<div className="row">
<div className="col-md-12">

<h1 className="head1">HOW TO LEARN MORE IN LESS TIME</h1>
<p><b>General Tips:</b></p>
<ul className="lilist">
<li>Take up first the subject you consider most difficult; once you have done this, the rest of your work will be easy.</li>
<li>Do not hesitate to ask for your teacher or your companion’s support if you do not understand a lesson clearly. Never leave difficulties unanswered as this will put you into greater difficulties later.</li>
<li>To make sure that you have understood something thoroughly, use these ‘tricks’.
<ul>
<li>(a)  Pretend that you are explaining the lesson to another; see if you can do it well.</li>
<li>(b)  Make yourself your own teacher and question yourself and answer honestly</li>
</ul>
</li>
<li>Do not continue working when you are tired; walk round the garden, or talk to a friend or have some other pleasant interruption, but only long enough to refresh you. Finish your lessons and don’t waste time.</li>
<li>Do enough exercise every day; it keeps the body and the mind fresh.</li>
<li>Do not cut down necessary sleep in order to study; it will harm you later on.</li>
<li>Lastly, pray often to the All-Knowing God to help you make progress in your studies.</li>
</ul>
<p><b>Specific Tips:</b></p>
<ul className="lilist">
<li>Never omit the lesson or home-work assigned; if you do so, it is like missing a train you can never catch afterwards.</li>
<li>Always revise the work done in class and make sure that you have understood everything clearly and thoroughly.</li>
<li>If memory work is assigned, learn it overnight and repeat it in the morning.</li>
<li>Always take down in your diary the work that is assigned for the next day.</li>
<li>All home work should be done neatly and the day’s work always dated. Each new piece of home work should be done on a fresh page</li>
<li>As you grow up, make a daily time-table for yourself for your study (the different subjects in order of difficulty, the most difficult one being first), for your rest, recreation etc. In this way you will learn to be a person of regular habits –a most valuable asset in later life.
</li>
<li>Revise at the end of the week all that was learnt during the week.</li>
</ul>
<img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/HOW_TO_LEARN.jpg" className="img-fluid mx-auto d-block imgspace img-thumbnail" alt="Loreto Convent School, Delhi Cantt"/>
</div>
</div>
</div>
</section>
   </>
  )
}

export default HowtoLearn
import React from 'react'
import { Link } from 'react-router-dom'
const AssessmentDesign = () => {
  return (
    <>
    <section className="page-header">
        <div className="page-header-bg"></div>
        <div className="page-header-shape-1"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/page-header-shape-1.png" alt="Loreto Convent School, Delhi Cantt"/></div>
        <div className="container">
        <div className="page-header__inner">
            <ul className="thm-breadcrumb list-unstyled ml-0">
                <li><Link to="/"> Home</Link></li>
                <li> Prospectus </li>
                <li> Assessment Design  </li>
            </ul>
            <h2>Assessment Design </h2>
        </div>
        </div>
    </section>
    <section className="innerbody">
<div className="container">
<div className="row">
<div className="col-md-12">
<h1 className="head1">PATTERN OF EXAMINATION</h1>
<ul className="lilist">
<li>There is a continuous comprehensive evaluation of students periodically in the form of tests and assessment.</li>
<li>No formal examination is conducted for Classes Prep to IV.</li>
<li>For Classes V to XII the examination will be as per the schedule and pattern laid out by CBSE.</li>
  </ul>
</div>
<div className="col-md-12">
<h1 className="head1">Please note:</h1>
<ul className="lilist">
<li>Students of Classes V to XII must secure overall 33% in each of the six subjects.</li>
<li>For classes XI & XII a student must pass In  both the theory and pratical exam.</li>
<li>No change of subject will be allowed after 1<sup>st</sup> october.</li>
<li>No changes of subject is allowed in Class XII.</li>
<li>It is mandatory for students to attend all tests and examinations except on medical grounds.</li>
<li>Medical certificate to be personally submitted within three days from the date of absence.</li> 
</ul>
</div>
</div>
</div>
</section>
   </>
  )
}

export default AssessmentDesign
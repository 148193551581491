import React, { useEffect, useState } from "react";
import { getVideoGallery } from "../Service/Api";
import { Link } from 'react-router-dom'
const VideoGallery = () => {
    const [data, setData] = useState([]);
    const [filterData, setFilteredData] = useState([]);
    const [selectedYear, setSelectedYear] = useState("All"); // Default to "All"
    const [yearRanges, setYearRanges] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            const galleryData = await getVideoGallery();
            setData(galleryData);

            // Extract unique years
            const uniqueYears = Array.from(
                new Set(galleryData.flatMap((item) => {
                    if (item.date) {
                        const year = new Date(item.date).getFullYear(); // Extract only the year
                        console.log(year)
                        return [year];
                    } else {
                        return [];
                    }
                }))
            );

            // Remove the map function for generating year ranges
            const uniqueYearRanges = uniqueYears.map(year => {
                return `${year}`;
            });

            uniqueYearRanges.sort((a, b) => b - a);
            console.log("Unique Year Ranges:", uniqueYearRanges); // Log uniqueYearRanges
            setYearRanges(uniqueYearRanges);
        };
        fetchData();
    }, []);

    useEffect(() => {
        // Filter data based on selected year
        let filteredData = data;
        if (selectedYear !== "All") {
            filteredData = filteredData.filter((item) => {
                if (item.date) {
                    const year = new Date(item.date).getFullYear();
                    return parseInt(selectedYear) === year;
                } else {
                    return false;
                }
            });
        }
        setFilteredData(filteredData);
    }, [selectedYear, data]);

    return (
        <>
            <section className="page-header">
                <div className="page-header-bg"></div>
                <div className="page-header-shape-1"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/page-header-shape-1.png" alt="Loreto Convent School, Delhi Cantt" /></div>
                <div className="container">
                    <div className="page-header__inner">
                        <ul className="thm-breadcrumb list-unstyled ml-0">
                            <li><Link to="/"> Home</Link></li>
                            <li>  Gallery </li>
                            <li> Video Gallery  </li>
                        </ul>
                        <h2>Video Gallery </h2>
                    </div>
                </div>
            </section>
            <section className="innerbody">
                <div className="container">
                    <div className="row inner_cont">
                        <div className="col-lg-12">
                            <div className="chooseyear">
                                <select id="chooseddlYear"
                                    value={selectedYear}
                                    onChange={(e) => setSelectedYear(e.target.value)}
                                    className="yearSelection"
                                >
                                    <option value={"All"}>All</option>
                                    {yearRanges.map((yr) => (
                                        <option key={yr} value={yr}>{yr}</option>
                                    ))}
                                </select>

                            </div>
                            <div className="clr"></div>
                            <div className="row">
                                {filterData.length > 0 && filterData.map((item, index) => (
                                    <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 videosearch" key={index}>
                                        <div className="video-gallery">
                                            <Link to={item.videoUrl} target="_blank">
                                                <img src={`https://d280nq1n4mqyso.cloudfront.net/api/image/${item?.attachments}`} alt="Loreto Convent School, Delhi Cantt - New Delhi" className="img-fluid mx-auto d-block" />
                                                <h3>{item.title}</h3>
                                            </Link>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default VideoGallery
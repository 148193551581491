import React, { useState, useEffect } from 'react'; 
import { Link } from 'react-router-dom';
import Slider from 'react-slick'; 
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { getGalleryInner } from "../Service/Api";

const KidsCorner = () => {
    const [data, setData] = useState([]); // State for gallery data
    const [loading, setLoading] = useState(true); // State for loading indicator

    useEffect(() => {
        const fetchData = async () => {
            try {
                const kidsGallery1 = await getGalleryInner();
                console.log("Fetched Data:", kidsGallery1);
                
                const kidsGallery = kidsGallery1.filter((item) => item.category === 'KidsCorner'); 
                console.log("Filtered KidsCorner Data:", kidsGallery);

                // Sort the data by date in descending order and then slice to get the last 6 entries
                const getcurrentData = kidsGallery
                    .sort((a, b) => new Date(b.date) - new Date(a.date))
                    .slice(0, 6);
                
                setData(getcurrentData);
                console.log("Current Data:", getcurrentData);
            } catch (error) {
                console.error("Error fetching gallery data:", error);
                // Handle error (e.g., show error message)
            } finally {
                setLoading(false); // Set loading to false after data fetching is complete
            }
        };
        fetchData();
    }, []);
    const settings = {
        dots: false,
        infinite: false,
        speed: 1000,
        slidesToShow: 2,
        margin: 0,
        slidesToScroll: 1,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                },
            },
            {
                breakpoint: 1000,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                },
            },
            {
                breakpoint: 700,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                },
            },
        ],
    };
    // const options = {
    //     loop: false,
    //     margin: 0,
    //     dots: false,
    //     nav: false,
    //     responsiveClass: true,
    //     autoplay: true,
    //     smartSpeed: 1000,
    //     responsive: {
    //         0: { items: 1 },
    //         400: { items: 1 },
    //         600: { items: 1 },
    //         700: { items: 1 },
    //         1000: { items: 1 },
    //         1200: { items: 2 },
    //     },
    // };

    return (
        <>
            <div className="row">
            <Slider {...settings}>
                    {data?.length > 0 ? (
                        data.map((item, index) => (
                            <div className="item" key={index}>
                                <div className="project-item">
                                    <div className="project-thumb">
                                        <Link to=" ">
                                            <img
                                                src={`https://d280nq1n4mqyso.cloudfront.net/api/image/${item?.attachments[0]}`}
                                                alt="Loreto Convent School Delhi Cantt"
                                                className="img-fluid"
                                            />
                                        </Link>
                                    </div>
                                    <div className="project-content">
                                        <h2 className="title">{item.title}</h2>
                                        <span>{item.description}</span>
                                    </div>
                                </div>
                            </div>
                        ))
                    ) : (
                        <div className="item" key="no-data">
                            <div className="project-item">
                                <div className="project-thumb">
                                    <Link to=" ">
                                        <img
                                            src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/gal-1.jpg"
                                            alt="Loreto Convent School Delhi Cantt"
                                            className="img-fluid"
                                        />
                                    </Link>
                                </div>
                                <div className="project-content">
                                    <h2 className="title">Album</h2>
                                    <span>Name</span>
                                </div>
                            </div>
                        </div>
                    )}
                </Slider>
            </div>
        </>
    );
};

export default KidsCorner;

import React from 'react'
import { Link } from 'react-router-dom'
import {SlideshowLightbox} from 'lightbox.js-react'
const Maxims = () => {
  return (
    <>
    <section className="page-header">
        <div className="page-header-bg"></div>
        <div className="page-header-shape-1"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/page-header-shape-1.png" alt="Loreto Convent School, Delhi Cantt"/></div>
        <div className="container">
        <div className="page-header__inner">
            <ul className="thm-breadcrumb list-unstyled ml-0">
                <li><Link to="/"> Home </Link></li>
                <li> Founder </li>
                <li> Maxims  </li>
            </ul>
            <h2>Maxims </h2>
        </div>
        </div>
    </section>
    <section className="innerbody">
<div className="container">
   <div className="row">
    <div className="col-md-12">
          <div className="flexbox-v zoompic maximspic">
                 <div>
                   <SlideshowLightbox><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/Maxims1.jpg" alt="Loreto Convent School Delhi Cantt" className="img-fluid"/></SlideshowLightbox>
                </div>
                <div>
                   <SlideshowLightbox><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/Maxims2.jpg" alt="Loreto Convent School Delhi Cantt" className="img-fluid"/></SlideshowLightbox>
                </div>
                <div>
                   <SlideshowLightbox><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/Maxims3.jpg" alt="Loreto Convent School Delhi Cantt" className="img-fluid"/></SlideshowLightbox>
                </div>
                <div>
                   <SlideshowLightbox><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/Maxims4.jpg" alt="Loreto Convent School Delhi Cantt" className="img-fluid"/></SlideshowLightbox>
                </div>
                <div>
                   <SlideshowLightbox><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/Maxims5.jpg" alt="Loreto Convent School Delhi Cantt" className="img-fluid"/></SlideshowLightbox>
                </div>
                <div>
                   <SlideshowLightbox><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/Maxims6.jpg" alt="Loreto Convent School Delhi Cantt" className="img-fluid"/></SlideshowLightbox>
                </div>
                <div>
                   <SlideshowLightbox><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/Maxims7.jpg" alt="Loreto Convent School Delhi Cantt" className="img-fluid"/></SlideshowLightbox>
                </div>
                <div>
                   <SlideshowLightbox><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/Maxims8.jpg" alt="Loreto Convent School Delhi Cantt" className="img-fluid"/></SlideshowLightbox>
                </div>
                <div>
                   <SlideshowLightbox><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/Maxims9.jpg" alt="Loreto Convent School Delhi Cantt" className="img-fluid"/></SlideshowLightbox>
                </div>
                <div>
                    <SlideshowLightbox><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/Maxims10.jpg" alt="Loreto Convent School Delhi Cantt" className="img-fluid"/></SlideshowLightbox>
                </div>
                <div>
                    <SlideshowLightbox><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/Maxims11.jpg" alt="Loreto Convent School Delhi Cantt" className="img-fluid"/></SlideshowLightbox>
                </div>
                <div>
                    <SlideshowLightbox><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/Maxims12.jpg" alt="Loreto Convent School Delhi Cantt" className="img-fluid"/></SlideshowLightbox>
                </div>
                <div>
                    <SlideshowLightbox><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/Maxims13.jpg" alt="Loreto Convent School Delhi Cantt" className="img-fluid"/></SlideshowLightbox>
                </div>
                <div>
                    <SlideshowLightbox><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/Maxims14.jpg" alt="Loreto Convent School Delhi Cantt" className="img-fluid"/></SlideshowLightbox>
                </div>
                <div>
                    <SlideshowLightbox><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/Maxims15.jpg" alt="Loreto Convent School Delhi Cantt" className="img-fluid"/></SlideshowLightbox>
                </div>
                <div>
                    <SlideshowLightbox><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/Maxims16.jpg" alt="Loreto Convent School Delhi Cantt" className="img-fluid"/></SlideshowLightbox>
                </div>
                <div>
                    <SlideshowLightbox><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/Maxims17.jpg" alt="Loreto Convent School Delhi Cantt" className="img-fluid"/></SlideshowLightbox>
                </div>
                <div>
                    <SlideshowLightbox><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/Maxims18.jpg" alt="Loreto Convent School Delhi Cantt" className="img-fluid"/></SlideshowLightbox>
                </div>
                <div>
                    <SlideshowLightbox><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/Maxims19.jpg" alt="Loreto Convent School Delhi Cantt" className="img-fluid"/></SlideshowLightbox>
                </div>
                <div>
                    <SlideshowLightbox><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/Maxims20.jpg" alt="Loreto Convent School Delhi Cantt" className="img-fluid"/></SlideshowLightbox>
                </div>
                <div>
                    <SlideshowLightbox><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/Maxims21.jpg" alt="Loreto Convent School Delhi Cantt" className="img-fluid"/></SlideshowLightbox>
                </div>
                <div>
                    <SlideshowLightbox><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/Maxims22.jpg" alt="Loreto Convent School Delhi Cantt" className="img-fluid"/></SlideshowLightbox>
                </div>
                <div>
                    <SlideshowLightbox><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/Maxims23.jpg" alt="Loreto Convent School Delhi Cantt" className="img-fluid"/></SlideshowLightbox>
                </div>
                <div>
                    <SlideshowLightbox><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/Maxims24.jpg" alt="Loreto Convent School Delhi Cantt" className="img-fluid"/></SlideshowLightbox>
                </div>
                <div>
                    <SlideshowLightbox><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/Maxims25.jpg" alt="Loreto Convent School Delhi Cantt" className="img-fluid"/></SlideshowLightbox>
                </div>
                <div>
                    <SlideshowLightbox><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/Maxims26.jpg" alt="Loreto Convent School Delhi Cantt" className="img-fluid"/></SlideshowLightbox>
                </div>
                <div>
                    <SlideshowLightbox><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/Maxims27.jpg" alt="Loreto Convent School Delhi Cantt" className="img-fluid"/></SlideshowLightbox>
                </div>
                 <div>
                    <SlideshowLightbox><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/Maxims28.jpg" alt="Loreto Convent School Delhi Cantt" className="img-fluid"/></SlideshowLightbox>
                </div>
                 <div>
                    <SlideshowLightbox><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/Maxims29.jpg" alt="Loreto Convent School Delhi Cantt" className="img-fluid"/></SlideshowLightbox>
                </div>
                 <div>
                    <SlideshowLightbox><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/Maxims30.jpg" alt="Loreto Convent School Delhi Cantt" className="img-fluid"/></SlideshowLightbox>
                </div>
                 <div>
                    <SlideshowLightbox><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/Maxims31.jpg" alt="Loreto Convent School Delhi Cantt" className="img-fluid"/></SlideshowLightbox>
                </div>
                 <div>
                    <SlideshowLightbox><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/Maxims32.jpg" alt="Loreto Convent School Delhi Cantt" className="img-fluid"/></SlideshowLightbox>
                </div>
                 <div>
                    <SlideshowLightbox><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/Maxims33.jpg" alt="Loreto Convent School Delhi Cantt" className="img-fluid"/></SlideshowLightbox>
                </div>
                 <div>
                    <SlideshowLightbox><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/Maxims34.jpg" alt="Loreto Convent School Delhi Cantt" className="img-fluid"/></SlideshowLightbox>
                </div>
                 <div>
                    <SlideshowLightbox><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/Maxims35.jpg" alt="Loreto Convent School Delhi Cantt" className="img-fluid"/></SlideshowLightbox>
                </div>
                 <div>
                    <SlideshowLightbox><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/Maxims36.jpg" alt="Loreto Convent School Delhi Cantt" className="img-fluid"/></SlideshowLightbox>
                </div>
                 <div>
                    <SlideshowLightbox><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/Maxims37.jpg" alt="Loreto Convent School Delhi Cantt" className="img-fluid"/></SlideshowLightbox>
                </div>
                 <div>
                    <SlideshowLightbox><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/Maxims40.jpg" alt="Loreto Convent School Delhi Cantt" className="img-fluid"/></SlideshowLightbox>
                </div>
                 <div>
                    <SlideshowLightbox><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/Maxims41.jpg" alt="Loreto Convent School Delhi Cantt" className="img-fluid"/></SlideshowLightbox>
                </div>
                <div>
                    <SlideshowLightbox><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/Maxims43.jpg" alt="Loreto Convent School Delhi Cantt" className="img-fluid"/></SlideshowLightbox>
                </div>
                
                <div>
                    <SlideshowLightbox><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/Maxims45.jpg" alt="Loreto Convent School Delhi Cantt" className="img-fluid"/></SlideshowLightbox>
                </div>

                <div>
                    <SlideshowLightbox><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/Maxims49.jpg" alt="Loreto Convent School Delhi Cantt" className="img-fluid"/></SlideshowLightbox>
                </div>
                <div>
                    <SlideshowLightbox><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/Maxims51.jpg" alt="Loreto Convent School Delhi Cantt" className="img-fluid"/></SlideshowLightbox>
                </div>
                <div>
                    <SlideshowLightbox><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/Maxims52.jpg" alt="Loreto Convent School Delhi Cantt" className="img-fluid"/></SlideshowLightbox>
                </div>
                <div>
                    <SlideshowLightbox><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/Maxims53.jpg" alt="Loreto Convent School Delhi Cantt" className="img-fluid"/></SlideshowLightbox>
                </div>
                <div>
                    <SlideshowLightbox><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/Maxims54.jpg" alt="Loreto Convent School Delhi Cantt" className="img-fluid"/></SlideshowLightbox>
                </div>
                <div>
                    <SlideshowLightbox><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/Maxims55.jpg" alt="Loreto Convent School Delhi Cantt" className="img-fluid"/></SlideshowLightbox>
                </div>
                <div>
                    <SlideshowLightbox><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/Maxims57.jpg" alt="Loreto Convent School Delhi Cantt" className="img-fluid"/></SlideshowLightbox>
                </div>
                <div>
                    <SlideshowLightbox><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/Maxims58.jpg" alt="Loreto Convent School Delhi Cantt" className="img-fluid"/></SlideshowLightbox>
                </div>
                <div>
                    <SlideshowLightbox><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/Maxims59.jpg" alt="Loreto Convent School Delhi Cantt" className="img-fluid"/></SlideshowLightbox>
                </div>
                <div>
                    <SlideshowLightbox><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/Maxims60.jpg" alt="Loreto Convent School Delhi Cantt" className="img-fluid"/></SlideshowLightbox>
                </div>
                     <div>
                    <SlideshowLightbox><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/Maxims61.jpg" alt="Loreto Convent School Delhi Cantt" className="img-fluid"/></SlideshowLightbox>
                </div>
                <div>
                    <SlideshowLightbox><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/Maxims62.jpg" alt="Loreto Convent School Delhi Cantt" className="img-fluid"/></SlideshowLightbox>
                </div>
                <div>
                    <SlideshowLightbox><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/Maxims63.jpg" alt="Loreto Convent School Delhi Cantt" className="img-fluid"/></SlideshowLightbox>
                </div>
                <div>
                    <SlideshowLightbox><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/Maxims64.jpg" alt="Loreto Convent School Delhi Cantt" className="img-fluid"/></SlideshowLightbox>
                </div>
                <div>
                    <SlideshowLightbox><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/Maxims65.png" alt="Loreto Convent School Delhi Cantt" className="img-fluid"/></SlideshowLightbox>
                </div>
                <div>
                    <SlideshowLightbox><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/Maxims66.jpg" alt="Loreto Convent School Delhi Cantt" className="img-fluid"/></SlideshowLightbox>
                </div>
                <div>
                    <SlideshowLightbox><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/Maxims67.png" alt="Loreto Convent School Delhi Cantt" className="img-fluid"/></SlideshowLightbox>
                </div>
                <div>
                    <SlideshowLightbox><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/Maxims68.png" alt="Loreto Convent School Delhi Cantt" className="img-fluid"/></SlideshowLightbox>
                </div>
                <div>
                    <SlideshowLightbox><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/Maxims69.png" alt="Loreto Convent School Delhi Cantt" className="img-fluid"/></SlideshowLightbox>
                </div>
                <div>
                    <SlideshowLightbox><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/Maxims70.png" alt="Loreto Convent School Delhi Cantt" className="img-fluid"/></SlideshowLightbox>
                </div>
             </div>
    </div>
    </div>
</div>
</section>
   </>
  )
}

export default Maxims
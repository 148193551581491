import { useEffect, useState } from "react";
import React from 'react'
import { useNavigate, Link } from "react-router-dom"
import { getGalleryInner } from "../Service/Api";
const JpicPage = () => {
   const navigate = useNavigate()
   const [data, setData] = useState([]);
   const [filterData, setFilteredData] = useState([]);
   const [selectedYear, setSelectedYear] = useState("All");
   const [selectedMonth, setSelectedMonth] = useState("All");
   const [monthOptions, setMonthOptions] = useState(["All"]);
   const [yearRanges, setYearRanges] = useState([]);
   const [searchQuery, setSearchQuery] = useState("");
 
   useEffect(() => {
       const fetchData = async () => {
           const galleryData1 = await getGalleryInner(); 
           const galleryData = galleryData1.filter((item) => item.category === 'JPIC'); 
           setData(galleryData);
           const uniqueYears = Array.from(
               new Set(galleryData.flatMap((item) => {
                   if (item.date) {
                       const year = new Date(item.date).getFullYear();
                       return [year];
                   } else {
                       return [];
                   }
               }))
           );
           const uniqueYearRanges = uniqueYears.map(year => `${year}`);
           uniqueYearRanges.sort((a, b) => b - a);
           setYearRanges(uniqueYearRanges);
 
           const uniquemonth = Array.from(
               new Set(galleryData.flatMap((item) => {
                   if (item.date) {
                       const month = new Date(item.date).getMonth() + 1;
                       return [month];
                   } else {
                       return [];
                   }
               }))
           );
           const uniqueMonths = uniquemonth.map(month => `${month}`);
           uniqueMonths.sort((a, b) => b - a);
           setMonthOptions(uniqueMonths);
       };
       fetchData();
   }, []);
 
   useEffect(() => {
       let filteredData = data;
       if (selectedYear !== "All") {
           filteredData = filteredData.filter((item) => {
               if (item.date && item.date.trim() !== "") {
                   const year = new Date(item.date).getFullYear();
                   const month = new Date(item.date).getMonth() + 1;
                   return parseInt(selectedYear) === year && (selectedMonth === "All" || parseInt(selectedMonth) === month);
               } else {
                   return false;
               }
           });
       }
       if (selectedMonth !== "All") {
           filteredData = filteredData.filter((item) => {
               if (item.date && item.date.trim() !== "") {
                   const month = new Date(item.date).getMonth() + 1;
                   return parseInt(selectedMonth) === month;
               } else {
                   return false;
               }
           });
       }
       if (searchQuery) {
           filteredData = filteredData.filter((item) =>
               item.title.toLowerCase().includes(searchQuery.toLowerCase())
           );
       }
       setFilteredData(filteredData);
   }, [selectedYear, selectedMonth, searchQuery, data]);
   const handleModal = (id) => {
     navigate(`/JpicGallery?id=${id}`);
   };
   const emptyArray = [
     // { description: "“Educating the mind without educating the heart is no education at all.” Sacred Heart has been educating boys and girls since 1990; though much has changed over the years our mission remains the same. Sacred Heart is committed to developing students of high academic achievement, intellectual curiosity, and strong moral character. As a result, year after year Sacred Heart sends our respectful, responsible and compassionate young adults, who are well prepared to tackle the challenges that lay ahead. Since arriving at Sacred Heart in 2016, I have had the opportunity to observe this unique institution up close. I have seen its commitment to academic excellence, its dynamic student body, and incredibly committed faculty and a loving community where the students and families are proud and supportive of one another. As I walk down the hallways of the school every day, I can hear the chatter of eager minds; the shouts of excitement from the victorious athletes, the thumb of dancers feed and the sound of melodious voices harmonizing the perpetual energy, movement and enthusiasm permeate the atmosphere. Ours is a school with difference. We value creativity and innovation and strive to nurture them in our students. Sacred Heartians are groomed to become confident, articulate and enlightened young citizens ready to step into the global society with dignity, honour and integrity. Aristotle once said “Educating the mind without educating the heart is no education at all”. Even as we impart education to match the advancement in technology and globalization, we march our children ahead with Sacred Heart ethos of moral values and principles. We Endeavour constantly to instill these qualities in our children. We pride ourselves to help them grow and develop into sensitive, responsible God loving citizens of the future. May the Sacred Heart of Jesus bless us abundantly in all our endeavourers.", attachments: "director.jpg" },   
   ]
  return (
    <>
    <section className="page-header">
        <div className="page-header-bg"></div>
        <div className="page-header-shape-1"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/page-header-shape-1.png" alt="Loreto Convent School, Delhi Cantt"/></div>
        <div className="container">
        <div className="page-header__inner">
            <ul className="thm-breadcrumb list-unstyled ml-0">
                <li><Link to="/"> Home</Link></li>
                <li> JPIC </li>
            </ul>
            <h2>JPIC </h2>
        </div>
        </div>
    </section>
    <section className="innerbody">
    <div className="container">
       <div className="row">
          <div className="col-md-12">
             <div className="accordion achievementacc" id="accordionExample">
               
                 <div class="accordion-item">
                  <div className="card">
                   <div className="card-header">
                      <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                      JPIC in Loretos
                      </button>
                   </div>
                   <div id="collapseOne" className="collapse show" data-bs-parent="#accordionExample">
                      <div className="card-body">
                         <table className="table table-striped table-bordered">
                            <tbody>
                               <tr>
                                  <td>
                                     <Link to="http://www.loretohousekolkata.com/" target="_blank">Loreto House School 7,<br/>
                                     Middleton Row, Kolkata PIN: 700 071. </Link>
                                  </td>
                                  <td>
                                     <Link to="http://www.loretobowbazar.in/" target="_blank">Loreto, Bowbazar 65,<br/>
                                     B. B. Ganguli St. Kolkata – 700 072  </Link>
                                  </td>
                               </tr>
                               <tr>
                                  <td>
                                     <Link to="http://www.loretoentally.org/" target="_blank">Loreto Convent 1,<br/>
                                     Convent Lane P.O. Tengra Kolkata PIN: 700 015  </Link>
                                  </td>
                                  <td>
                                     <Link to="http://www.loretohousekolkata.com/" target="_blank">Loreto House College 7,<br/>
                                     Middleton Row, Kolkata – 700 071 </Link>
                                  </td>
                               </tr>
                               <tr>
                                  <td>
                                     <Link to="http://www.loretodharamtala.in/" target="_blank">Loreto Day School,<br/>
                                     Dharamtala 169, Lenin Sarani Kolkata – 700 013  </Link>
                                  </td>
                                  <td>
                                     <Link to="http://www.loretoelliot.org/" target="_blank">Loreto Day School,<br/>
                                     Elliot Road 9, Elliot Road Kolkata – 700 016  </Link>
                                  </td>
                               </tr>
                               <tr>
                                  <td>
                                     <Link to="http://www.loretosealdah.org/" target="_blank">Loreto Day School,<br/>
                                     Sealdah 122, A.J.C. Bose Road Kolkata – 700 014  </Link>
                                  </td>
                                  <td>
                                     <Link to="http://www.loretoconventdrj.in/" target="_blank">Loreto Convent School<br/>
                                     Darjeeling 734 101 West Bengal  </Link>
                                  </td>
                               </tr>
                               <tr>
                                  <td>
                                     <Link to="http://www.loretoasansol.in/" target="_blank">Loreto Convent, Asansol,<br/>
                                     W. Bengal PIN: 713 301  </Link>
                                  </td>
                                  <td>
                                     <Link to="../index.html" target="_blank">Loreto Convent, Parade Road,<br/>
                                     Delhi Cantt, New Delhi PIN: 110 010  </Link>
                                  </td>
                               </tr>
                               <tr>
                                  <td>
                                     <Link to="http://loretolucknow.com/" target="_blank">Loreto Convent, Intermediate College
                                     99,<br/>
                                     Mahatma Gandhi Marg, Lucknow, U.P 226001  </Link>
                                  </td>
                                  <td>
                                     <Link to=" ">St. Teresa’s Girls H. S. School<br/>
                                     Darjeeling 734 101 West Bengal </Link>
                                  </td>
                               </tr>
                               <tr>
                                  <td>
                                     <Link to="http://www.loretoconventshillong.com/" target="_blank">Loreto Convent, Shillong<br/>
                                     793 003 Meghalaya  </Link>
                                  </td>
                                  <td>
                                     <Link to="http://www.loretoshimla.org/" target="_blank">Loreto Convent Tara Hall Shimla<br/>
                                     171 003 Himachal Pradesh  </Link>
                                  </td>
                               </tr>
                               <tr>
                                  <td>
                                     <Link to="http://www.loretodoranda.org/" target="_blank">Loreto Convent Doranda H.P.O.<br/>
                                     Ranchi Jharkhand PIN – 834002  </Link>
                                  </td>
                                  <td>
                                     <Link to="http://www.stagnesloretolko.com/" target="_blank">St. Agnes’, Loreto Day School
                                     33,<br/>
                                     Station Road Lucknow - 226001 Uttar Pradesh  </Link>
                                  </td>
                               </tr>
                               <tr>
                                  <td>
                                     <Link to=" ">Loreto Primary School Lolay<br/>
                                     P.O Dokandara Kalimpong 734 301 West Bengal </Link>
                                  </td>
                                  <td>
                                     <Link to=" ">Loreto Junior High School<br/>
                                     P.O Panighatta Bazaar Via Belgachi Dist. Darjeeling West Bengal </Link>
                                  </td>
                               </tr>
                               <tr>
                                  <td>
                                     <Link to=" ">Loreto Primary School<br/>
                                     P.O Sadam Via Melli South Sikkim 737128 </Link>
                                  </td>
                                  <td>
                                     <Link to=" ">Loreto Convent School Darjeeling<br/>
                                     734 101 West Bengal </Link>
                                  </td>
                               </tr>
                            </tbody>
                         </table>
                      </div>
                   </div>
                </div>
                </div>
                 <div class="accordion-item">
                  <div className="card">
                   <div className="card-header">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                      JPIC song
                      </button>
                   </div>
                   <div id="collapseTwo" className="collapse" data-bs-parent="#accordionExample">
                      <div className="card-body">
                         <table className="table table-striped table-bordered">
                            <p className="text-center">
                               God is our creator<br/>
                               God is the way<br/>
                               God loves us all<br/>
                               Out in the world there are people praying saying<br/>
                               Let your mercy fall
                            </p>
                            <br/>
                            <p className="text-center">
                               Let us join our hand and stay under one roof<br/>
                               Let the power of love fill our heart and make our spirit move<br/>
                               Together we can make the change and transform the world<br/>
                               That’s all we want
                            </p>
                            <br/>
                            <p className="text-center">
                               Chorus: Lets turn it all around<br/>
                               We can make the change<br/>
                               If we put our hands together<br/>
                               We’ll find a way
                            </p>
                            <br/>
                            <p className="text-center">
                               Justice, peace, integrity<br/>
                               That’s what we strive for<br/>
                               Years of inequality<br/>
                               Now will be no more
                            </p>
                            <br/>
                            <p className="text-center">
                               Peace is all we seek and love is all we spread<br/>
                               With your gracious hands you bind us with the same thread<br/>
                               We will change the world and make it alright<br/>
                               The sun will shine.
                            </p>
                            <br/>
                            <p className="text-center">
                               Chorus: Lets turn it all around<br/>
                               We can make the change<br/>
                               If we put our hands together<br/>
                               We’ll find a way …(3)
                            </p>
                         </table>
                      </div>
                   </div>
                </div>
                </div>
                 <div class="accordion-item">
                  <div className="card">
                   <div className="card-header">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">JPIC Prayer</button>
                   </div>
                   <div id="collapseThree" className="collapse" data-bs-parent="#accordionExample">
                      <div className="card-body">
                         <table className="table table-striped table-bordered">
                            <p className="text-center">
                               God My Loving Mother and Father
                               <br/>
                               <br/>
                               In the Warmth of my Home, Let me remember the homeless
                               <br/>
                               <br/>
                               In the aroma of my food, let me remember the starving
                               <br/>
                               <br/>
                               In the wholeness of my body, let me remember the Impaired
                               <br/>
                               <br/>
                               In the abundance of my Blessings
                               <br/>
                               <br/>
                               Let me not embrace vanity but let my actions speak,
                               <br/>
                               <br/>
                               Silently serving Humanity.
                            </p>
                         </table>
                      </div>
                   </div>
                </div>
 
                </div>
                 <div class="accordion-item">
                  <div className="card">
                   <div className="card-header">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseThree">Newsletter/E-magazine</button>
                   </div>
                   <div id="collapseFour" className="collapse" data-bs-parent="#accordionExample">
                      <div className="card-body">
                         <table className="table table-striped table-bordered">
                            <ul className="lilist">
                            <li> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/pdf/Laudato-Si-Week.pdf" target="_blank">Laudato Si' Week (24 May 2020)- <b>Click Here</b></Link> </li>
                               <li> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/pdf/75th-Anniversary.pdf" target="_blank">75th Anniversary of the UN (September 2020)- <b>Click Here</b></Link></li>
                               <li> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/pdf/JPIC-E-Magazine-2021.pdf" target="_blank">JPIC-E-Magazine (March 2021)- <b>Click Here</b></Link></li>
 
                               <li> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/pdf/1st-MW-JPIC-2021.pdf" target="_blank">1st Mary Ward JPIC South Asia General Meet 2021- <b>Click Here</b></Link> </li>
                               <li> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/pdf/earth-day-activity.pdf" target="_blank">Earth Day Activity- <b>Click Here</b></Link></li>
                               <li> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/pdf/un-update-48-sept.pdf" target="_blank">United Nations- <b>Click Here</b></Link></li>
                            </ul>
                         </table>
                      </div>
                   </div>
                </div>
 
                </div>
                 <div class="accordion-item">
                  <div className="card">
                   <div className="card-header">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">JPIC Gallery</button>
                   </div>
                   <div id="collapseFive" className="collapse" data-bs-parent="#accordionExample">
                      <div className="card-body">
                         <div className="row">
                         <div className="row tabs-dynamic">
            <div className="col-xxl-3 col-xl-3 col-lg-6 col-12">
              <div className="count-val">
                <p>Total Categories:  {filterData.length} </p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-3 col-lg-6 col-12">
              <div className="month-selection">

              <select  
                                 value={selectedYear}
                                 onChange={(e) => setSelectedYear(e.target.value)}
                                 className="yearSelection">
                                 <option value={"All"}>All</option>
                                 {yearRanges.map((yr) => (
                                    <option key={yr} value={yr}>{yr}</option>
                                 ))}
                              </select>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-3 col-lg-6 col-12">
              <div className="month-selection">
              <select
                        value={selectedMonth}
                        onChange={(e) => setSelectedMonth(e.target.value)}
                        className="monthSelect"
                     ><option value={"All"}>All</option>
                        {monthOptions.map((month, index) => (
                           <option key={index} value={month}>{month}</option>
                        ))}
                     </select>
              </div>
            </div>

            <div className="col-xxl-3 col-xl-3 col-lg-6 col-12">
              <div className="searchBox">
              <input
                    type="text"
                    id="myInput"
                    name="name"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    placeholder="Search Here.."
                />
              </div>
            </div>
          </div>
                            <div className="row years year2023 showYear">
                            {filterData?.length > 0 ? filterData?.filter((div) => div.category === "JPIC").map((item, index) => (

<div className="col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-12 galleryCount"  key={index}>
  <div className="gal_new">
    <article>
      <img src={`https://d280nq1n4mqyso.cloudfront.net/api/image/${item?.attachments[0]}`} alt="Loreto Convent School Delhi Cantt" className="img-fluid" />
      <div className="gal-content">
        <h4 className="catgry-name">{item.title}</h4>
        <p><strong>Description: </strong>{item.description}</p>
        <h6 className="date"><i className="bi bi-calendar-week"></i> {item.date}
          <span> <a onClick={() => navigate(`/JpicGallery?id=${item._id}`)}>View Album  <i className="bi bi-arrow-right"></i> </a></span>
        </h6>
        <div className="total-img"><i className="bi bi-card-image"></i><p>{item?.attachments.length}</p></div>
      </div>
    </article>
  </div>
</div>

)):emptyArray.map((data, index) => ( <div key={index} className='col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-12 galleryCount'>

            <div className="gal_new">
                        <article>
                           <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/gal-1.jpg"  alt="Loreto Convent School Delhi Cantt" className="img-fluid"/>
                           <div className="gal-content">
                              <h4 className="catgry-name">Category Name</h4>
                              <p><strong>Description: </strong>Stay Tuned for Latest Updates</p>
                              <h6 className="date"><i className="bi bi-calendar-week"></i> DD MM YYYY                                                 
                                  <span> <Link to="/SubGallery">View Album  <i className="bi bi-arrow-right"></i> </Link></span>
                              </h6>
                              <div className="total-img"><i className="bi bi-card-image"></i><p>5</p></div>
                           </div>
                        </article>
                    </div>
            
</div>
))}
                            </div>
                             
                         </div>
                      </div>
                   </div>
                </div>
             </div>
             </div>
          </div>
       </div>
    </div>
 </section>
   </>
  )
}

export default JpicPage
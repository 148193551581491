import React from 'react'
import { Link } from 'react-router-dom'
const ConductDiscipline = () => {
  return (
    <>
    <section className="page-header">
        <div className="page-header-bg"></div>
        <div className="page-header-shape-1"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/page-header-shape-1.png" alt="Loreto Convent School, Delhi Cantt"/></div>
        <div className="container">
        <div className="page-header__inner">
            <ul className="thm-breadcrumb list-unstyled ml-0">
                <li><Link to="/"> Home</Link></li>
                <li> Prospectus </li>
                <li> Conduct Discipline  </li>
            </ul>
            <h2>Conduct Discipline </h2>
        </div>
        </div>
    </section>
    <section className="innerbody">
<div className="container">
<div className="row">
<div className="col-md-12">
<h1 className="head1">GENERAL DIRECTIVES OF CONDUCT AND DISCIPLINE</h1>
<p><b>The enrolment in _Loreto Convent School, Delhi implies
   on the part of pupils and parents, a willingness to comply
   with the regulations of the school.</b>
</p>
<h1 className="head1">1. Pupils are expected to:</h1>
<ul className="lilist">
   <li> Attend school punctually</li>
   <li> Wear their I- card daily to school.</li>
   <li> Behave in a disciplined and elegant manner on all
      occasions. They are expected to address all members
      of the staff with due respect and politeness.
   </li>
   <li> Treat other pupils with kindness and maintain an
      attitude of uprightness and self-restraint.
   </li>
   <li> Respond positively and take part in all school
      activities, even when such activities take place out of
      the usual school hours. Occasional stayback for Jeevan
      Rekha and Outreach programme is mandatory.
   </li>
   <li> Participate in excursions and picnics organised by the
      school.
   </li>
   <li>Subscribe towards school shows and charities, pay the
      requisite fees for all school educational trips, outreach
      prograqunes and extra/ co-curricular activities.
   </li>
   <li> Be responsible for their belongings. Books, bags,
      cardigans etc. should bear the name of the owner.
   </li>
   <li> Maintain school property with care. The cost of
      defacement or damage must be borne by the offender.
   </li>
   <li>Refrain from unfair means during tests/exams. Such  
      improper conduct is unworthy of a Loreto students and can lead to severe pemalties. No marks and no retest will be given in the subject in which the students has used unfair means.
   </li>
   <li><b>Bring their own stationery in a transparent pouch on examination days.</b></li>
</ul>
<h1 className="head1">2. PUPILS ARE EXPECTED TO REFRAIN FROM:</h1>
<ul className="lilist">
   <li>Bringing polybags and aluminium foil to school. The
      school is strictly 'No Polybag' and 'No Aluminium
      Foil' Zone.
   </li>
   <li>Bringing mobile phones, smart watch, cameras &
      other electronic items.
   </li>
   <li>Making Whatsapp groups or posting any school
      related content on social networking sites.
   </li>
   <li>Wearing jewellery. A simple wristwatch and small
      pair of stud earrings is permissible. Nose rings are
      not allowed.
   </li>
   <li>Wearing mehndi, using hair colour or streaks and
      nail polish. While at school, the hair must be neatly
      secured with hairclips and hair band.
   </li>
   <li>Tattoos are not permitted.</li>
   <li>Buying any article or food from unauthorized
      vendors outside or near the school premises.
   </li>
   <li>Lavish _ celebration of birthdays during school hours.
      Only sweets/toffees may be distributed for classes
      Prep to V. No bars of chocolate/cake permitted
   </li>
   <li>Wearing coloured lenses.</li>
   <li>Wearing the school uniform in public places outside
      of school hours.
   </li>
   <li>Eating chewing gum in school premises.</li>
   <li>Damaging /defacing the school uniform. Scribbling
      on the school uniform at any time, especially on the
      last day of the school, is strictly prohibited.
   </li>
   <li>Using white correction ink/ pen/ tape.</li>
</ul>
<p><b>3. Private tuition for children from any member of the
   School Staff is strictly forbidden. If a child needs
   private tuition, the advice of the Principal is to be taken
   before help is given.</b>
</p>
<p><b>4. All correspondence regarding the pupil should be
   neatly written and addressed to the Principal and sent
   in an envelope.</b>
</p>
<p><b>5. Parents are not allowed to meet their children during
   school hours without permissions of the Principal /
   Coordinators.</b>
</p>
<p>6. No tiflins, water bottles, stationery, etc. should be sent
   from home after the student reaches school.
</p>
<p>7. Half days or coming only for an examination is not
   permissible for students.
</p>
<p>8. Before meeting a teacher, please make an appointment
   by using the 'Communication from Parent to Teacher'
   section of the diary. Parents may meet the teachers or
   Coordinators only in the school lobby.
</p>
<p><b>9. A student who is genuinely ill or recovering from illness
   should not be sent to school for test / examination.
   Medical certificates must be sent to the school within
   three working days from the date of the student's
   absence. Only hard copy of the certificate will be
   accepted.</b>
</p>

<p>
   10. Parents to refrain from being part of social media groups
   to prevent miscommunication  A direct interaction with the
   concerned school personnel will solve the qeries. Demonstrate respect for the members of the school community; including students. The school will not tolerate any form of defamation or intentional hann
   practised through social media platforms.
</p>
<p>11. The Principal has the right to suspend the attendance or
   require the withdrawal of any pupil whose conduct is
   injurious to the tone of the school.
</p>
<p>12. The decision of the school authorities with regard to
   promotion is final.
</p>
<p>13. Any application submitted by parents would require at
   least 3 days to complete the official procedure.
</p>
</div>
</div>
</div>
</section>
   </>
  )
}

export default ConductDiscipline
import React from 'react'
import { Link } from 'react-router-dom'
const HeadGirlMessage = () => {
  return (
    <>
    <section className="page-header">
        <div className="page-header-bg"></div>
        <div className="page-header-shape-1"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/page-header-shape-1.png" alt="Loreto Convent School, Delhi Cantt"/></div>
        <div className="container">
        <div className="page-header__inner">
            <ul className="thm-breadcrumb list-unstyled ml-0">
                <li><Link to="/"> Home</Link></li>
                <li> Office Bearers </li>
                <li> Head Girl's Message  </li>
            </ul>
            <h2>Head Girl's Message </h2>
        </div>
        </div>
    </section>
    <section className="innerbody">
   <div className="container">
   <div className="row pt-5">
      <div className="container">
         <div className="col-md-12">
            <div className="accordion achievementacc" id="accordionExample">
            <div class="accordion-item">
               <div className="card">
                  <div className="card-header">
                     <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEleven" aria-expanded="true" aria-controls="collapseEleven">
                     2024-25
                     </button>
                  </div>
                  <div id="collapseEleven" className="collapse show" data-bs-parent="#accordionExample">
                     <div className="card-body">
                        <div className="head_girl"> <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/KAASHVI-MALHOTRA.jpg" className="img-fluid mx-auto d-block" alt="Loreto Convent School Delhi Cantt"/></div>
                          
    <p>I am deeply honoured and humbled to hold an office as prestigious as this for the academic year 2024-25. As Head Girl of this esteemed institution, I am filled with gratitude to the Almighty and thankful to our Principal Mrs. Alice Rose D’lima, to all my teachers, and to my dear school mates and juniors for considering me deserving of this position.</p>
    <p>Having entered the school at a mere age of 11, this institution has raised me in every sense of the term, and I am delighted to have this opportunity to give back what I have received. My years here have influenced my value system and the way I hold myself to an extent that’s immeasurable and I can only hope to reflect those values as I stand with my fellow council members, leading them through this Jubilee year.</p>
    <p>As a child, I had always looked up to my seniors, as role models, my eyes filled with admiration at how they held themselves, how they spoke, how kind they were to their juniors. As I follow in their footsteps today, I hope I have made myself worthy enough to be looked at in that light. </p>
    <p>‘Women in time to come will do much.’ Loreto has given me the potential to do that and as Head girl of this Jubilee session I hope to make this institute proud, as I am proud to have been a student here. </p>

                       
                       
                        <p className="text-end">
                        Best wishes<br/>
                           <strong style={{color: "#bd2151"}}> Kaashvi Malhotra</strong>
                    
                        </p>
                     </div>
                  </div>
               </div>
               </div>
                
              <div class="accordion-item">
               <div className="card">
                  <div className="card-header">
                     <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEleven" aria-expanded="true" aria-controls="collapseEleven">
                     2023-24
                     </button>
                  </div>
                  <div id="collapseEleven" className="collapse" data-bs-parent="#accordionExample">
                     <div className="card-body">
                        <div className="head_girl"> <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/Angela-Elizabeth2023.jpg" className="img-fluid mx-auto d-block" alt="Loreto Convent School Delhi Cantt"/></div>
                          
    <p>It is indeed a great privilege to be the Head Girl of Loreto Convent School, Delhi Cantt. and to have the opportunity to lead the school along with my fellow Student Council members. I am deeply honoured and blessed for being part of this Loreto family, who has placed their trust and confidence in me. </p>
    <p>Having moved from Loreto House, Kolkata to Loreto Convent School, Delhi, this institution has rooted its values in me. I have grown, in maturity, in virtue, and have learnt how to be humble while also being proud of the person I am now- the person this school has shaped me to be. </p>
    <p>For this I thank God, through whom everything is made possible. I owe gratitude to our Principal, Mrs D'Lima, who has proved time and again that kindness conquers all; to my teachers, who have taught me to work hard without expecting praise; to the Support Staff, who have taught me that respect is earned by gesture, not position; to my family and friends who have supported me and believed in me throughout. I also express my gratitude to all the staff and Sisters of the Community for making this possible. </p>
    <p>I envision the students of Loreto Convent to be exemplars of good values, and highly equipped with knowledge and self dignity, because I believe that every student is capable of being the best person they can be. I hope that we, the Student Council 2023-24, inspire you to grow more in love, happiness and peace, as we strive to fulfil the dream and vision of our founder, Venerable Mary Ward. </p>

                       
                       
                        <p className="text-end">
                           Best Wishes<br/>
                           <strong style={{color: "#bd2151"}}> Angela Elizabeth  Domingo</strong>
                    
                        </p>
                     </div>
                  </div>
               </div>
               </div>
              <div class="accordion-item">
               <div className="card">
                  <div className="card-header">
                     <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                     2022-23
                     </button>
                  </div>
                  <div id="collapseOne" className="collapse" data-bs-parent="#accordionExample">
                     <div className="card-body">
                        <div className="head_girl"> <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/MERYL-TERESA-ANTHONY-2022.jpg" className="img-fluid mx-auto d-block" alt="Loreto Convent School Delhi Cantt"/></div>
                      
                        <p>I am humbled and filled with gratitude to the Almighty and am greatly indebted and thankful to our 
                           Principal, Sr. Monica, and the Management and Staff of Loreto Convent School, Delhi for believing 
                           in me to hold office as the Head Girl for the year 2022 23.
                        </p>
                        <p>As a leader, I stand before you not only to lead you but also to be one among you to work for the
                           betterment of the school. The school has taught me how to deal with challenges. It has made me a
                           better student, a better citizen, and above all, a better person. 
                        </p>
                        <p>Down the memory lane, I still remember the first step I took into this institution, as a 4-year-old 
                           girl who was curious to explore a new world. I had wandered off to the Book Fair section and was 
                           standing in awe when the then Coordinator spotted me and told my father not to worry about me because
                           I was already home.
                        </p>
                        <p>Apart from academic excellence, this institution has always encouraged me to learn new things, which 
                           has had a significant impact on my holistic growth from a 4-year-old to now being elected as the
                           Head Girl of this prestigious institution- a dream come true for every girl studying here.
                        </p>
                        <p>As the school motto goes, 'Receive that you may give', I hope to give back to the institution more 
                           than what I have received and imbibed all these years.
                        </p>
                        <p>May Venerable Mary Ward bless us all!</p>
                        <p className="text-end">
                           Best Wishes<br/>
                           <strong style={{color: "#bd2151"}}>MERYL TERESA ANTHONY</strong>
                   
                        </p>
                     </div>
                  </div>
               </div>
               </div>
              <div class="accordion-item">
               <div className="card">
                  <div className="card-header">
                     <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                     2020-21
                     </button>
                  </div>
                  <div id="collapseTwo" className="collapse" data-bs-parent="#accordionExample">
                     <div className="card-body">
                        <div className="head_girl"> <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/NovelleJibbyJohn.png" className="img-fluid mx-auto d-block" alt="Loreto Convent School Delhi Cantt"/></div>
                        <p>To be called upon to uphold the legacy of Venerable Mary Ward is a matter of pride and honour. As the Head Girl for the 
                           academic year 2020-2021, I express immense gratitude to the teachers and students for placing their trust in the 
                           Student Council, 2020-21. 
                        </p>
                        <p>With your vote of confidence in us, we aspire to live up to the values of love, freedom and integrity exhibited by
                           the Loreto institution. The Student council is not only limited to 17 members, but includes each one of you in our
                           quest to achieve the goals envisioned by the Loreto Sisters. We endeavour to be your representatives, a channel for
                           your voices and ideas. In these troubled times brought about by the pandemic, we seek your prayers, guidance and 
                           collective support as we face and overcome challenges. 
                        </p>
                        <p>Echoing the words of Venerable Mary Ward, “Women in time to come will do much”, let us come together as harbingers of change and 
                           peace.
                        </p>
                        <br/><br/>
                        <p className="text-end">
                           Best Wishes<br/>
                           <strong style={{color: "#bd2151"}}>Novelle Jibby John</strong>
                   
                        </p>
                     </div>
                  </div>
               </div>
               </div>
              <div class="accordion-item">
               <div className="card">
                  <div className="card-header">
                     <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                     2019-20
                     </button>
                  </div>
                  <div id="collapseThree" className="collapse" data-bs-parent="#accordionExample">
                     <div className="card-body">
                        <div className="head_girl"> <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/RIAJAIN.jpg" className="img-fluid mx-auto d-block" alt="Loreto Convent School Delhi Cantt"/></div>
                        <p><b>Step up to the challenge<br/>
                           Step out without fear <br/>
                           All will be well, for God is near. 
                           </b>
                        </p>
                        <p>I, the Head Girl for the academic year 2019-20, feel privileged to be a part of this dynamic institution which upholds
                           the values of love, freedom, sincerity and justice. 
                        </p>
                        <p>We, the members of the Student Council, endeavour to empower the students and thus proclaim the glory of God. Each one of you is 
                           equally important to us because it is only with your prayers, guidance and cooperation that our effort will bear fruit. 
                        </p>
                        <p>Our commitment will be to carry forward the Loreto legacy, "RECEIVE THAT YOU MAY GIVE". We look forward to enthusiastic participation by 
                           all the students in all activities while working hard in academics. I believe that talents are God's gift which should not be kept 
                           unexplored in one corner. 
                        </p>
                        <p>We together will be firm on our principles and courageous in our actions.  </p>
                        <p className="text-end">
                           Best Wishes<br/>
                           <strong style={{color: "#bd2151"}}>RIA JAIN</strong>
                      
                        </p>
                     </div>
                  </div>
               </div>
               </div>
              <div class="accordion-item">
               <div className="card">
                  <div className="card-header">
                     <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                     2018-19
                     </button>
                  </div>
                  <div id="collapseFour" className="collapse" data-bs-parent="#accordionExample">
                     <div className="card-body">
                        <div className="head_girl"> <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/REBECCA-JOSE.jpg" className="img-fluid mx-auto d-block" alt="Loreto Convent School Delhi Cantt"/></div>
                        <p><b>“Women in time to come will do much”.</b></p>
                        <p>These words of Venerable Mother Mary Ward empower every student
                           of her institute with education, skills and values to be their best.
                        </p>
                        <p>It is each student who makes Loreto and Loreto who makes each student
                           who she is and what she can become.
                        </p>
                        <p>Therefore I ask all of you to come together and with the grace
                           of god we will build our Alma Mater with great zeal and love.
                        </p>
                        <p className="text-end">
                           Best Wishes<br/>
                           <strong style={{color: "#bd2151"}}>Rebecca Jose</strong>
                   
                        </p>
                     </div>
                  </div>
               </div>
               </div>
              <div class="accordion-item">
               <div className="card">
                  <div className="card-header">
                     <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                     2017-18
                     </button>
                  </div>
                  <div id="collapseFive" className="collapse" data-bs-parent="#accordionExample">
                     <div className="card-body">
                        <div className="head_girl"> <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/head_girl_18.jpg" className="img-fluid mx-auto d-block" alt="Loreto Convent School Delhi Cantt"/></div>
                        <p><b>“Leadership is an action not a position”</b></p>
                        <p>It is indeed a great privilege to be elected as the&nbsp;head&nbsp;girl&nbsp;for this
                           academic session 2017-18.
                        </p>
                        <p>I am grateful to God for his grace and blessings and I also want to
                           express my heartfelt gratitude to all of you for reposing your faith
                           in me for shouldering such a great responsibility.
                        </p>
                        <p>On behalf of all the members of the Student Council I promise
                           that we will all strive for excellence in all spheres&nbsp; , aiming to not
                           only make paths for others to follow but also create opportunities
                           for all of you to become trail blazers in your own right
                        </p>
                        <p>
                           I am fortunate to have had inspiring teachers through the 12 years
                           that I’ve spent at Loreto, who've helped me in metamorphosing
                           from a very shy&nbsp;girl&nbsp;to acquiring the qualities of a leader.
                        </p>
                        <p>I understand that my appointment calls for me to give my all and
                           to be an example worthy of emulation. I ask for your support and
                           cooperation to fulfill my charter of responsibilities with diligence.
                        </p>
                        <p>This year let us plant trees for a greener tomorrow and plant
                           seeds of Mary ward’s vision in our hearts to become the lady she
                           dreamt of us.
                        </p>
                        <p>We will travel this road together and work in unison to keep
                           Loreto’s banner flying high up in the sky, mounted with love,
                           &nbsp;hoisted with dignity,
                           and fluttering with grace.
                        </p>
                        <p className="text-end">
                           Best Wishes<br/>
                           <strong style={{color: "#bd2151"}}>Lydia Nenghoithem Haokip</strong>

                        </p>
                     </div>
                  </div>
               </div>
               </div>
              <div class="accordion-item">
               <div className="card">
                  <div className="card-header">
                     <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                     2016-17
                     </button>
                  </div>
                  <div id="collapseSix" className="collapse" data-bs-parent="#accordionExample">
                     <div className="card-body">
                        <div className="head_girl"> <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/894.jpg" className="img-fluid mx-auto d-block" alt="Loreto Convent School Delhi Cantt"/></div>
                        <p>Little would Mary Ward, our founder know of me, but she knew of us girls and young women centuries ago. She knew that we needed to be made strong and empowered to take up the challenges in the real world.</p>
                        <p>The sisters and teachers in our Loreto schools, particularly here, endeavour to empower us with knowledge, the skills of mind and body to solve problems big and small. This self-confidencegives us faith in ourselves and in others and above all a deep sense of values and commitment that bind us to God, to each other and to humanity.</p>
                        <p>I am here before you, humbled by the faith reposed by you all in me as the School Head Girl. Respected Sister Mercia, my Teachers and fellow students, you have given me this mantle of responsibility and my faith in God and in you all makes me confident that God will take us to greater paths of glory for our dear school.</p>
                        <p>
                           Come with me girls as you and I and all of us,in the Student Council make our school a better place to study and grow, into young confident women who are not afraid to take our rightful place in the world and walk the earth with pride and dignity, proud to be products of our Alma Mater.
                        </p>
                        <p>I promise to uphold the values of our school and to fulfil your expectations of me with sincerity and passion as your Head Girl. Thank you all and God above for giving me this opportunity to take on the responsibility and lead the student body in the service of our school and the greater glory of God.</p>
                        <p className="text-end">
                           Best Wishes<br/>
                           <strong style={{color: "#bd2151"}}>Zoya Jaffery</strong>
                     
                        </p>
                     </div>
                  </div>
               </div>
               </div>
              <div class="accordion-item">
               <div className="card">
                  <div className="card-header">
                     <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                     2015-16
                     </button>
                  </div>
                  <div id="collapseSeven" className="collapse" data-bs-parent="#accordionExample">
                     <div className="card-body">
                        <div className="head_girl">  <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/Fiza-Jaggi(Head-girl).jpg" className="img-fluid mx-auto d-block" alt="Loreto Convent School Delhi Cantt"/></div>
                        <p>At the outset, I wish to express my heartfelt gratitude to the almighty first and then to Sr. Mercia and all teachers for reposing their faith in me. I thank you all my fellow students for using your voice, your vote to express your confidence in me. I am grateful for having being blessed with this opportunity to serve my alma mater and you my schoolmates as the Head Girl. I acknowledge that Loreto has moulded me into what I am today-both in thought and conduct, a legacy that I will carry with me forever. I do not take this responsibility lightly and I shall endeavour to represent you with utmost transparency and boldness.</p>
                        <p>I am sure that I speak on behalf of all my fellow student council members when I say to each of you that just as much as you’ve seen leaders in us, know that there is a friend too, to share your every concern and offer you support.</p>
                        <p>Together, we will endeavour to make it one of the most memorable years at school.</p>
                        <p>Let’s make Loreto proud by excelling at everything that we do, be it academics, sports, or any other extracurricular activity.</p>
                        <p>I promise to lead the school not with position, but with passion, and I need your constant support to achieve it.</p>
                        <p className="text-end">
                           Best Wishes<br/>
                           <strong style={{color: "#bd2151"}}>  FIZA JAGGI</strong>
                     
                        </p>
                     </div>
                  </div>
               </div>
               </div>
              <div class="accordion-item">
               <div className="card">
                  <div className="card-header">
                     <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
                     2014-15
                     </button>
                  </div>
                  <div id="collapseEight" className="collapse" data-bs-parent="#accordionExample">
                     <div className="card-body">
                        <div className="head_girl">   <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/head-girl-Shaoni-kar.jpg" className="img-fluid mx-auto d-block" alt="Loreto Convent School Delhi Cantt"/></div>
                        <p>
                           Leadership is not about making speeches or being liked. It is defined by the results
                           and not by the attributes.
                        </p>
                        <p>
                           Firstly, I would to say a big thank you for believing in me and electing me as your
                           representative. I give you my word that I will do my best to serve this institution
                           which has given me so much... not only in education but a lot beyond and has been
                           my second home since quite some time now.
                        </p>
                        <p>
                           I, with the help of my fellow council members, surely intend to make a difference.
                           But that won't be possible unless all of you join us, as friends, as companions
                           in the school's journey towards even greater heights.
                        </p>
                        <p>
                           The badge you see here doesn't make us any different from you all. It's just for
                           reminding you constantly, that we are your representatives. Your troubles and doubts
                           are very much ours too. We are your speakers, the voice to your brilliant ideas
                           and opinions. So when you want to express any idea, do feel free to approach us.
                           I assure you, you will always find an ear willing to listen and a heart willing
                           to help.
                        </p>
                        <p>
                           As we know, this is Loreto's Golden Jubilee Year which makes it very very special
                           for all of us. So I'm looking forward to a whole lot of co-operation and whole-hearted
                           enthusiasm from your side. And since these school years are and will be the best
                           ones of your life, I suggest, let's make it worthwhile. I'm sure, if we join hands
                           today, we can make this 50th year a productive, fruitful, enjoyable and most importantly,
                           memorable one.
                        </p>
                        <p className="text-end">
                           Best Wishes<br/>
                           <strong style={{color: "#bd2151"}}>  Shaoni Kar</strong>
                         
                        </p>
                     </div>
                  </div>
               </div>
               </div>
              <div class="accordion-item">
               <div className="card">
                  <div className="card-header">
                     <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseNine" aria-expanded="false" aria-controls="collapseNine">
                     2013-14
                     </button>
                  </div>
                  <div id="collapseNine" className="collapse" data-bs-parent="#accordionExample">
                     <div className="card-body">
                        <div className="head_girl">    <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/head-girl-msg.jpg" className="img-fluid mx-auto d-block" alt="Loreto Convent School Delhi Cantt"/></div>
                        <p>
                           “Before you are a leader, success is all about growing yourself. Once you become
                           a leader, success is all about growing together”.
                        </p>
                        <p>
                           I would like to begin with expressing, on behalf of the STUDENT COUNCIL and myself,
                           immense gratitude to all the teachers and students for electing us and believing
                           in us.
                        </p>
                        <p>
                           I would like to reinforce the fact that the STUDENT COUNCIL is not restricted to
                           the 17 of us, but each one of you is equally important for achieving the goals that
                           we set. The only difference between you and us, is a distance of 7 centimeters which
                           is merely the length of the badges that we wear.
                        </p>
                        <p>
                           My personal vision for our school is that we may excel in every fields including
                           Academics, Sports and co-curriculars, but my foremost vision is that each one of
                           you may get a chance to display your talents. Mark my words when I say this, when
                           you win something for yourself, it is for you and your family, but when you win
                           laurels for our school, it is for you, your family, plus 2000 more people. The happiness
                           and satisfaction thus, will be 2000 times more!
                        </p>
                        <p>
                           A word to my co-council members-“It is nice to be important, but it is more important
                           to be nice.” So let us all keep aside our differences and start working together,
                           not for me, or for you, but for our Loreto family.
                        </p>
                        <p className="text-end">
                           Best Wishes<br/>
                           <strong style={{color: "#bd2151"}}> Adwaidha L. Santosh</strong>
                        
                        </p>
                     </div>
                  </div>
               </div>
               </div>
              <div class="accordion-item">
               <div className="card">
                  <div className="card-header">
                     <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTen" aria-expanded="false" aria-controls="collapseTen">
                     2012-13
                     </button>
                  </div>
                  <div id="collapseTen" className="collapse" data-bs-parent="#accordionExample">
                     <div className="card-body">
                        <p> “On behalf of the members of STUDENT COUNCIL, I thank everyone for believing in
                           all of us. I am honoured to have got this opportunity to serve my school which has
                           helped me to be the person I am today.
                        </p>
                        <p> Having been a shy person myself, I can empathize with most girls in the school. I
                           want to play the role of that friend in each one of your lives, who pushes you beyond
                           your limits, who recognizes and acknowledges the talent in you and provide you with
                           the opportunities to showcase those talents. They are God’s gifts, not to be kept
                           unexplored in one corner. You can nurture them and let them shine. 
                        </p>
                        <p>  Ask yourself- Do you look at the horizon and see an opportunity or do you look at
                           the distance and see an obstacle ahead? 
                        </p>
                        <p> Dear friend, feel free to approach us- the STUDENT COUNCIL whenever you have a problem.
                           We will solve it together. We pray to God to help us fulfill our duties. 
                        </p>
                        <p className="text-end">
                           Best Wishes<br/>
                           <strong style={{color: "#bd2151"}}>Ashwathy Babu Paul</strong>
                     
                        </p>
                     </div>
                  </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
   </div>
</section>
   </>
  )
}

export default HeadGirlMessage
import React from 'react'
import { Link } from 'react-router-dom'
const ErpSoftware = () => {
  return (
    <>
    <section className="page-header">
        <div className="page-header-bg"></div>
        <div className="page-header-shape-1"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/page-header-shape-1.png" alt="Loreto Convent School, Delhi Cantt"/></div>
        <div className="container">
        <div className="page-header__inner">
            <ul className="thm-breadcrumb list-unstyled ml-0">
                <li><Link to="/"> Home</Link></li>
                <li> ERP Software  </li>
            </ul>
            <h2>ERP Software </h2>
        </div>
        </div>
    </section>
    <section className="innerbody">
<div className="container">
<div className="erpbox">
<div className="erpboxdesc">
<p class="details"> Entab Infotech Pvt Ltd., a company formed in 2000 and serving the school with automation technologies has evolved out as the best service provider through their advanced <Link to="/https://www.entab.in/school-management-erp.html"> <b>ERP Software:</b></Link> 'CampusCare®' and communication tools. We are also proud to be associated with them for managing the data of students, exam, fees, staff and  their accounts etc in addition to the <Link to="/https://www.entab.in/school-communication-portals.html"> <b> communication portal</b></Link> and 
<Link to="/https://www.entab.in/mobile-apps.html">
   <b> mobile apps</b>
 </Link>.<br/>
<br/>
The entire system is strengthening the academic of the students and preparing responsible staff and students. Together the reputation of the school grows higher. We value the relation with <Link to="/https://www.entab.in/"> <b>Entab: CampusCare®</b></Link> and look forward to receive the much more in technological front. </p>
    <div className="erpboxdescpopup">
            <div className="erppopup">
                <h4 className="imgheading"><Link to="/https://www.entab.in/school-management-erp.html"> Manage Data</Link></h4>
                <img id="myImg" src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/Manage-Data.gif" alt="School Management System, Management ERP Software" className="animation center-block" data-bs-toggle="modal" data-bs-target="#staticBackdrop"/>
                <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                    <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/Manage-Data.gif" alt="School Management System, Management ERP Software" className="img-fluid"/>
                    <h5>School Management System, Management ERP Software</h5>   
                </div>
                </div>
            </div>
            </div>
        </div>
        <div className="erppopup">
        <h4 className="imgheading"><Link to="/https://www.entab.in/school-communication-portals.html#PPC"> Parent Portal</Link></h4> 
                <img id="myImg1" src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/Parent-Portal.gif" alt="Entab Parent Portal, School Software" className="animation center-block" data-bs-toggle="modal" data-bs-target="#staticBackdrop1"/>
                <div className="modal fade" id="staticBackdrop1" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                    <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/Parent-Portal.gif" alt="Entab Parent Portal, School Software" className="img-fluid"/>
                    
                </div>
                </div>
            </div>
            </div>
    </div>
    <div className="erppopup">
    <h4 className="imgheading"><Link to="/https://www.entab.in/school-communication-portals.html#PPC"> Communication Portal</Link></h4> 
            <img id="myImg2" src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/communication.gif" alt="School Communication Portal" className="animation  center-block" data-bs-toggle="modal" data-bs-target="#staticBackdrop2"/>
            <div className="modal fade" id="staticBackdrop2" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div className="modal-dialog">
            <div className="modal-content">
                <div className="modal-header">
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/communication.gif" alt="School Communication Portal" className="img-fluid"/>
                <h5>School Communication Portal</h5>
            </div>
            </div>
        </div>
        </div>

    </div>
</div>
</div>
<div className="erpboximg">
<h4 className="imgheading"><Link to="/https://www.entab.in/mobile-apps.html">CampusCare</Link></h4>
<center><Link to="/https://www.entab.in/mobile-apps.html"> <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/Mobile-slides.gif" className="img-fluid" alt="School ERP Software, Mobile App, School Management Software, Software for School"/></Link> </center>
</div>
</div>
</div>
</section>
   </>
  )
}

export default ErpSoftware
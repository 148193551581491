import React, { useState, useEffect } from 'react';
import { TransformWrapper, TransformComponent, useControls } from 'react-zoom-pan-pinch';
import HTMLFlipBook from 'react-pageflip';

const Magazine2021 = () => {
    const [active, setActive] = useState(true);
    const [activebook, setActivebook] = useState(true);
    const [activebook1, setActivebook1] = useState(true);
    const [target, setTarget] = useState(null);

    const handleShowFlip = () => {  
        setActive(true);
        setActivebook(true);
        setActivebook1(false);
    }; 
    const handleShowFlip1 = () => {  
        setActive(true);
        setActivebook(false);
        setActivebook1(true);
    }; 
    useEffect(() => {
        setTimeout(() => {
            setActive(false);
            
        setActivebook(true);
            setActivebook1(true);
            // document.querySelector(".flipOpen").style.display = "none"
        }, 200);
    }, []);

    const handleClose = () => {
        setActive(false); 
        setActivebook(false);
        setActivebook1(false);
    };

    const Controls = () => {
        const { zoomIn, zoomOut, resetTransform } = useControls();
        return (
            <div className="control-btn">
                <div onClick={zoomIn}>+</div>
                <div onClick={zoomOut}>-</div>
                <div onClick={resetTransform}>Reset</div>
            </div>
        );
    };
    return (
        <>
            <div className={`flip-popup active`} style={{ display: active ? 'block' : 'none' }}>
                <div className="closeme" onClick={handleClose}>+</div>

                <div className={`flipOpen activebook`}  style={{ display: activebook ? 'block' : 'none' }}>
                    <HTMLFlipBook
                        width={500}
                        height={680}
                        minWidth={315}
                        maxWidth={1000}
                        minHeight={400}
                        maxHeight={1200}
                        mobileScrollSupport={true}
                        showCover={true}
                        useMouseEvents={true}
                        className="flipPageComponent"
                        onChange={(e) => setTarget(e)}
                        pageBackground="rgb(255, 255, 255)"
                        uncutPages="true"
                    >
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ntm1.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ntm2.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ntm3.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ntm4.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ntm5.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ntm6.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ntm7.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ntm8.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ntm9.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ntm10.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ntm11.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ntm12.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ntm13.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ntm14.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ntm15.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ntm16.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ntm17.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ntm19.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ntm20.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ntm21.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ntm22.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ntm23.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ntm24.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ntm25.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ntm26.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ntm27.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ntm28.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ntm29.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ntm30.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ntm31.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ntm32.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ntm34.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ntm35.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ntm36.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ntm37.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ntm38.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ntm39.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ntm40.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ntm41.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ntm42.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ntm43.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ntm44.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ntm45.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ntm46.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ntm47.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ntm48.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ntm49.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ntm50.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ntm51.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ntm52.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ntm53.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ntm54.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ntm55.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ntm56.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ntm57.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ntm58.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ntm59.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ntm60.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ntm61.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ntm62.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ntm63.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ntm64.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ntm65.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ntm66.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ntm67.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ntm68.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ntm69.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ntm70.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ntm71.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ntm72.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ntm73.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ntm74.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ntm75.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ntm76.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ntm77.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ntm78.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ntm79.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ntm80.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ntm81.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ntm82.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ntm83.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ntm84.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ntm86.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ntm87.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ntm88.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ntm89.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ntm90.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ntm91.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ntm92.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ntm93.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ntm94.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ntm95.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ntm96.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ntm97.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ntm98.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ntm99.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ntm100.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ntm101.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ntm102.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ntm103.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ntm104.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ntm105.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ntm106.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ntm107.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ntm108.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ntm109.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ntm110.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ntm111.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ntm112.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ntm113.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ntm114.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ntm115.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ntm116.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ntm117.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ntm118.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ntm119.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ntm120.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ntm121.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ntm122.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ntm123.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ntm124.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ntm125.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ntm126.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ntm127.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ntm128.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ntm129.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ntm130.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ntm131.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ntm132.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ntm133.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ntm134.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ntm135.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ntm136.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ntm137.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ntm138.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ntm139.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ntm140.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ntm141.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ntm142.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ntm143.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ntm144.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ntm145.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ntm146.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ntm147.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ntm148.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ntm149.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ntm150.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ntm151.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ntm152.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ntm153.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ntm154.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ntm155.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ntm156.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ntm157.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ntm158.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ntm159.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ntm160.jpg" /></div>
                    </HTMLFlipBook>
                </div>
                <div className={`flipOpen activebook1`}  style={{ display: activebook1 ? 'block' : 'none' }}>
                    <HTMLFlipBook
                        width={500}
                        height={680}
                        minWidth={315}
                        maxWidth={1000}
                        minHeight={400}
                        maxHeight={1200}
                        mobileScrollSupport={true}
                        showCover={true}
                        useMouseEvents={true}
                        className="flipPageComponent"
                        onChange={(e) => setTarget()}
                        pageBackground="rgb(255, 255, 255)"
                        uncutPages="true"
                    >
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ato0.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ato1.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ato2.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ato3.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ato4.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ato5.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ato6.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ato7.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ato8.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ato9.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ato10.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ato11.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ato12.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ato13.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ato14.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ato15.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ato16.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ato17.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ato19.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ato20.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ato21.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ato22.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ato23.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ato24.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ato25.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ato26.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ato27.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ato28.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ato29.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ato30.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ato31.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ato32.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ato34.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ato35.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ato36.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ato37.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ato38.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ato39.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ato40.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ato41.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ato42.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ato43.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ato44.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ato45.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ato46.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ato47.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ato48.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ato49.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ato50.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ato51.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ato52.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ato53.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ato54.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ato55.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ato56.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ato57.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ato58.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ato59.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ato60.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ato61.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ato62.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ato63.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ato64.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ato65.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ato66.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ato67.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ato68.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ato69.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ato70.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ato71.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ato72.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ato73.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ato74.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ato75.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ato76.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ato77.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ato78.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ato79.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ato80.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ato81.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ato82.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ato83.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ato84.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ato86.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ato87.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ato88.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ato89.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ato90.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ato91.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ato92.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ato93.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ato94.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ato95.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ato96.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ato97.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ato98.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ato99.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ato100.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ato101.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ato102.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ato103.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ato104.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ato105.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ato106.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ato107.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ato108.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ato109.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ato110.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ato111.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ato112.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ato113.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ato114.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ato115.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ato116.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ato117.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ato118.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ato119.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ato120.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ato121.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ato122.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ato123.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ato124.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ato125.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ato126.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ato127.jpg" /></div>
                        <div><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ato128.jpg" /></div> 
                    </HTMLFlipBook>
                </div>

            </div>
            <div className="d-flex flipSelection">
                <div className=" tab-pane active" id="tab1">
                    <div className="flip-block showflip" data-trigger="1" onClick={handleShowFlip}>
                        <h3>NOVEMBER 2020 - MARCH 2021</h3>
                        <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ntm1n.jpg" alt=""></img>
                    </div>
                    <div className="flip-block  showflip" data-trigger="2" onClick={handleShowFlip1}>
                        <h3>APRIL 2020 - OCTOBER 2020</h3>
                        <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/magazine/ato0.jpg" alt=""></img>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Magazine2021
import React from 'react'
import { Link } from 'react-router-dom'
const CyberWarrior = () => {
  return (
    <>
    <section className="page-header">
        <div className="page-header-bg"></div>
        <div className="page-header-shape-1"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/page-header-shape-1.png" alt="Loreto Convent School, Delhi Cantt"/></div>
        <div className="container">
        <div className="page-header__inner">
            <ul className="thm-breadcrumb list-unstyled ml-0">
                <li><Link to="/"> Home</Link></li>
                <li> Office Bearers </li>
                <li> Cyber Warrior  </li>
            </ul>
            <h2>Cyber Warrior </h2>
        </div>
        </div>
    </section>
    <section className="innerbody">
<div className="container">
   <div className="row">
<div className="col-md-12">
<div id="carouselExampleCaptions" className="carousel slide" data-bs-ride="carousel">
  <div className="carousel-inner">
    <div className="carousel-item active">
        <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/CyberWarrior-1.jpg" className="d-block w-100" alt="Loreto Convent School, Delhi Cantt"/>

    </div>
    <div className="carousel-item">
      <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/CyberWarrior-2.jpg" className="d-block w-100" alt="Loreto Convent School, Delhi Cantt"/>
      
    </div>
    <div className="carousel-item">
     <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/CyberWarrior-3.jpg" className="d-block w-100" alt="Loreto Convent School, Delhi Cantt"/>
      
    </div>
  </div>
  <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Previous</span>
  </button>
  <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
    <span className="carousel-control-next-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Next</span>
  </button>
</div>
</div>
</div>
</div>
</section>
   </>
  )
}

export default CyberWarrior
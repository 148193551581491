import React from 'react'
import { Link } from 'react-router-dom'
const Footer = () => {
  return (
     <>
     <footer>
     <div className="footer-area footer-bg">
        <div className="footer-top">
           <div className="container-fluid fulwdh">
              <div className="row">
                 <div className="col-xl-2 col-lg-3 col-md-6 col-sm-6">
                    <div className="footer-widget">
                       <div className="fw-logo">
                           <Link to="/"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/footerlogo.png" alt="" className="img-fluid mx-auto d-block"/></Link>
                           <div class="footer-content">
                        <p>Loreto Convent School was founded in 1965. </p>
                        <ul class="list-wrap">
 
                           <li><a href="https://www.youtube.com/@loretoconventdelhi5817" target="_blank"><i class="bi bi-youtube"></i></a></li>
                        </ul> </div>
                       </div>
                    </div>
                 </div>
                 <div className="col-xl-2 col-lg-3 col-md-6 col-sm-6">
                    <div className="footer-widget">
                       <div className="fw-title">
                          <h4 className="title">Get Extra Links</h4>
                       </div>
                       <div className="fw-link">
                          <ul className="list-wrap">
                        
                             <li><Link to="Tc">TC Issued </Link></li>
                            <li><Link to="SchoolFee">fee structure </Link></li>
                            <li><Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/pdf/bus-routes.pdf" target="_blank">bus route </Link></li>
                             <li><Link to="ErpSoftware">School ERP Software </Link></li>
                             <li><Link to="TrainingWorkshop">Training & Workshop  </Link></li>
                             <li><Link to="JpicPage">JPIC </Link></li>
                          </ul>
                       </div>
                    </div>
                 </div>
                 <div className="col-xl-2 col-lg-3 col-md-6 col-sm-6">
                    <div className="footer-widget">
                       <div className="fw-title">
                          <h4 className="title">Founder</h4>
                       </div>
                       <div className="fw-link">
                          <ul className="list-wrap">
                              <li><Link to="MaryWard">Mary Ward </Link></li>
                               <li><Link to="PaintedLife">Painted Life </Link></li>
                               <li><Link to="Maxims">Maxims </Link></li>
                              <li><Link to="MaryWardsStamp">Mary Ward Stamp </Link></li>
                              <li><Link to="OurSpirituality">Our Spirituality </Link></li>
                          </ul>
                       </div>
                    </div>
                 </div>
                 <div className="col-xl-2 col-lg-3 col-md-6 col-sm-6">
                    <div className="footer-widget">
                       <div className="fw-title">
                          <h4 className="title">Quick Links </h4>
                       </div>
                       <div className="fw-link">
                          <ul className="list-wrap">
                             <li><Link to="/">Internal Safety Audit </Link></li>
                             <li><Link to="PanLoretoCelebration">Pan-Loreto Celebration </Link></li>
                            
                          </ul>
                       </div>
                       <div class="card-body">
                <h5>Download App</h5>
                <h5>Use URL: <a href="https://loretodelhi.campuscare.cloud/" target='_blank'>loretodelhi.campuscare.cloud/</a></h5>
                <a href="https://apps.apple.com/in/app/campuscare/id1034721587?platform=ipad&preserveScrollPosition=true#platform/ipad" target="_blank"> <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/ios.png" width="100px" class="img-fluid icons" alt="Loreto Delhi" /></a> 
                <a href="https://play.google.com/store/apps/details?id=com.campuscare.entab.ui" target="_blank"> <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/android.png" class="img-fluid icons" alt='Loreto Delhi' /></a> </div>
                    </div>
                 </div>
                 <div className="col-xl-4 col-lg-12 col-md-12 col-sm-12">
                    <div className="footer-widget">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d28028.169884070907!2d77.137872!3d28.584136000000004!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d1ddd31770e03%3A0x5f3db6646381587e!2sLoreto%20Convent%20Girls%20School!5e0!3m2!1sen!2sin!4v1713325530225!5m2!1sen!2sin" width="100%"  height="300" style={{border:"0"}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                 </div>
              </div>
           </div>
        </div>
        <div className="footer-bottom">
           <div className="container">
              <div className="row">
                 <div className="col-lg-12">
                    <div className="copyright-text">
                       <p>© 2024 All Copyright Reserved <span>Loreto Convent School,  Delhi Cantt,  New Delhi.</span> Developed by<span> <a href="https://www.entab.in/" target="_blank">Entab Infotech : CampusCare®</a></span></p>
                    </div>
  
                 </div>
          
              </div>
           </div>
        </div>
     </div>
  </footer>
     </>
  )
}

export default Footer

import React from 'react'
import { Link } from 'react-router-dom'
const BusRules = () => {
  return (
    <>
    <section className="page-header">
        <div className="page-header-bg"></div>
        <div className="page-header-shape-1"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/page-header-shape-1.png" alt="Loreto Convent School, Delhi Cantt"/></div>
        <div className="container">
        <div className="page-header__inner">
            <ul className="thm-breadcrumb list-unstyled ml-0">
                <li><Link to="/"> Home</Link></li>
                <li> Prospectus </li>
                <li> Bus Rules  </li>
            </ul>
            <h2>Bus Rules </h2>
        </div>
        </div>
    </section>
    <section className="innerbody">
<div className="container">
<div className="row">
<div className="col-md-12">
<ul className="lilist">
<li>Buses are available according to the bus routes arranged by the school and students must travel only in the bus allotted to them.
</li><li>Admission to school does not ensure a seat on any particular bus. If a bus is full, or does not cover the required area, parents will have to make their own arrangements.
</li><li>If a child changes her residence, automatic change to another school bus is not guaranteed.
</li><li>In case of joining / change / discontinuation of the school bus, parents are requested to fill up the requisite forms available in the accounts office and must be notified a month in advance or one month bus fee will be charged in lieu of notice.
</li><li>The bus fee has to be paid according to the bus fee schedule.</li>

<li>Any complaint regarding the bus transport should be brought to the notice of the Principal in writing.
</li><li>In case of any mishaps in the bus, please call at 011-25692299 immediately.
</li><li>Misbehaviour on the bus is sufficient reason for suspension from using the school transport facility.
</li><li>Parents are requested not to influence the staff on the bus for creating any new stop or for changing the route.</li>

</ul>


</div>
</div>
</div>
</section>
   </>
  )
}

export default BusRules
import React, { useEffect, useState } from 'react'
// import article1 from'../Assets/Images/article1.jpg'
// import article2 from'../Assets/Images/article2.jpg'
// import article3 from'../Assets/Images/article3.jpg'

import { getNews } from '../Service/Api';
import { Link } from 'react-router-dom'  
const HomeNews = () => {
   const [data, setData] = useState([]); // State for gallery data
   const [loading, setLoading] = useState(true); // State for loading indicator 
 
   useEffect(() => {
     const fetchData = async () => {
       try {
         const topperData = await getNews();
         setData(topperData);
       } catch (error) {
         console.error("Error fetching gallery data:", error);
         // Handle error (e.g., show error message)
       } finally {
         setLoading(false); // Set loading to false after data fetching is complete
       }
     };
     fetchData();
   }, []);
   
  return (
     <> 
     <div className="newssec-area">
     <marquee behavior="scroll" direction="up" onmouseover="this.stop()" onmouseout="this.start()">
     {data.map((item, index) => (
     <div className="newbox" key={index}>
           <div className="newssec-area-box-con">
              <h2>{item.title}</h2>
              <p>{item.description}</p>
              <h6>{item.attachments && item.attachments.length > 0 && (<Link to={`https://d280nq1n4mqyso.cloudfront.net/api/image/${item.attachments}`} className="link" target="_blank"> <p><i className="bi bi-calendar-event"></i>{item.date}</p> <span> Attachment </span></Link>)} </h6>
           </div>
        </div>
        ))}
        {/* <div className="newbox">
           <div className="newssec-area-box-con">
              <h2>final term syllabus for classes III - XI</h2>
              <p>final term syllabus for classes iii - Xi</p>
              <h6><Link to=" " className="link" target="_blank"> <p><i className="bi bi-calendar-event"></i>25 Jan, 2024</p> <span> Attachment </span></Link> </h6>
           </div>
        </div>
        
        <div className="newbox">
           <div className="newssec-area-box-con">
              <h2>Title</h2>
              <p>Coming Soon...</p>
              <h6><Link to=" " className="link" target="_blank"> <p><i className="bi bi-calendar-event"></i>dd mm yyyy</p> <span> Attachment </span></Link> </h6>
           </div>
        </div> */}
     </marquee>
  </div>
     </>
  )
}

export default HomeNews
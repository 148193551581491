import React from 'react'
import { Link } from 'react-router-dom'
const LegendaryPast = () => {
  return (
    <>
    <section className="page-header">
        <div className="page-header-bg"></div>
        <div className="page-header-shape-1"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/page-header-shape-1.png" alt="Loreto Convent School, Delhi Cantt"/></div>
        <div className="container">
        <div className="page-header__inner">
            <ul className="thm-breadcrumb list-unstyled ml-0">
                <li><Link to="/"> Home</Link></li>
                <li> About Us </li>
                <li> Legendary Past  </li>
            </ul>
            <h2>Legendary Past </h2>
        </div>
        </div>
    </section>
    <section className="innerbody">
<div className="container">
<div className="row">
<div className="col-md-12">
<p><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/clare.jpg"  className="img-fluid left-align-images" alt="Loreto Convent School, Delhi Cantt"/>In October 1964 two Loreto nuns came to Delhi- Mother M Francis Clare and Mother M Aquin. They lived at 28 Mall Road while the school building was under construction. Classes started in tents in January 1965 at 28 Mall Road, with Mother Francis Clare as the Founder Principal. By the end of the year there were 370 children on the rolls. In July 1966 a number of classrooms were ready and the school building on Parade Road was occupied. There were now 500 children in the school building. The number rose to 700 by 1969, and 840 by the end of 1970.
</p><p>The numbers had outgrown the available classrooms, and so a new wing was added in 1972 containing, besides extra classrooms, the much needed Science Laboratories for the Senior School. By 1980 the numbers had grown to 1200 and extra classrooms were urgently needed. These were built and rooms were also provided for Home Science and Work Experience.
</p><p>A major change occurred in 1987 when the school changed over from the I.C.S.E. to the C.B.S.E. pattern of education. After additional classrooms were constructed, in 1990, we were able to send up our first batch of class twelve students.
</p><p>We are deeply grateful to all our parents, friends and benefactors who, through their generosity over the years, have made the building plans possible. We also stand in gratitude and reverence before Mary Ward, that incomparable woman, who has inspired so many to follow in her footsteps.</p>
</div>
</div>

<div className="row align-images g-4">
<div className="col-md-4">
<img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/legendary-past-1.jpg"  className="img-fluid" alt="Loreto Convent School, Delhi Cantt"/>
</div>
<div className="col-md-4">
<img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/legendary-past-2.jpg"  className="img-fluid" alt="Loreto Convent School, Delhi Cantt"/>
</div>
<div className="col-md-4">
<img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/legendary-past-3.jpg"  className="img-fluid" alt="Loreto Convent School, Delhi Cantt"/>
</div>
</div>



</div>
</section>
   </>
  )
}

export default LegendaryPast
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom"; // Import useNavigate hook
import { getSubGallery } from "../Service/Api";
import { Link } from 'react-router-dom'
import {SlideshowLightbox} from 'lightbox.js-react'
const SubCategoryKindergarten = () => {
        
     const navigate = useNavigate();
     const location = useLocation();
     const id = new URLSearchParams(location.search).get("id");
     const [data, setData] = useState("");
     const [index, setIndex] = React.useState(-1);
   
     console.log(id);
   
     useEffect(() => {
       const getData = async () => {
         try {
           let subgalleryData = await getSubGallery(id);
           console.log(subgalleryData);
           setData(subgalleryData);
         } catch (error) {
           console.log(error);
         }
       };
       getData();
     }, []);
   
     console.log(data);
   
     const slides = data?.attachments?.map(img => ({
       src: `https://d280nq1n4mqyso.cloudfront.net/api/image/${img}`
     }));
  return (
    <>
    <section className="page-header">
        <div className="page-header-bg"></div>
        <div className="page-header-shape-1"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/page-header-shape-1.png" alt="Loreto Convent School, Delhi Cantt"/></div>
        <div className="container">
        <div className="page-header__inner">
            <ul className="thm-breadcrumb list-unstyled ml-0">
                <li><Link to="/"> Home</Link></li>
                <li>  Kids Corner</li>
            </ul>
            <h2> Kids Corner </h2>
        </div>
        </div>
    </section>
    <section className="innerbody">
    <div className="container">
       <div className="row">
          <div className="col-md-12">
    
               
    
             <div className="fulwidth">
                <div className="sub-gallery">
                   <div className="shrink"><Link to="/KidsCorner"><i className="bi bi-arrow-left"></i></Link></div>
                   <div><b>Event Date:</b>  {new Date(data.date).toLocaleDateString("en-GB")}</div>
                   <div><b>Updated On:</b> {new Date(data.date).toLocaleDateString("en-GB")}</div>
                   <div><b>Total Photos:</b>{data?.attachments?.length}</div>
                </div>
                <div className="discription">
                   <h3 className="subhead">{data.title}</h3>
                   <p><b>Description:</b> {data.description}</p>
                </div>
             </div>
             <div className="flexbox-v zoompic">
             {data !== "" &&
              data?.attachments.map((img,index) => (
                <div  key={index}>
                     <a data-magnify="gallery" data-src="" data-group="a" href={`https://d280nq1n4mqyso.cloudfront.net/api/image/${img}`} onClick={() => setIndex(index)}><img src={`https://d280nq1n4mqyso.cloudfront.net/api/image/${img}`} alt="Loreto Convent School Delhi Cantt" className="img-fluid"/></a>
                </div>
                  ))} 
                {/* <div>
                     <SlideshowLightbox><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/gal-1.jpg" alt="Loreto Convent School Delhi Cantt" className="img-fluid"/></SlideshowLightbox>
                </div>
                <div>
                     <SlideshowLightbox><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/gal-1.jpg" alt="Loreto Convent School Delhi Cantt" className="img-fluid"/></SlideshowLightbox>
                </div>
                <div>
                     <SlideshowLightbox><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/gal-1.jpg" alt="Loreto Convent School Delhi Cantt" className="img-fluid"/></SlideshowLightbox>
                </div>
                <div>
                     <SlideshowLightbox><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/gal-1.jpg" alt="Loreto Convent School Delhi Cantt" className="img-fluid"/></SlideshowLightbox>
                </div>
                <div>
                     <SlideshowLightbox><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/gal-1.jpg" alt="Loreto Convent School Delhi Cantt" className="img-fluid"/></SlideshowLightbox>
                </div>
                <div>
                     <SlideshowLightbox><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/gal-1.jpg" alt="Loreto Convent School Delhi Cantt" className="img-fluid"/></SlideshowLightbox>
                </div>
                <div>
                     <SlideshowLightbox><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/gal-1.jpg" alt="Loreto Convent School Delhi Cantt" className="img-fluid"/></SlideshowLightbox>
                </div>
                <div>
                     <SlideshowLightbox><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/gal-1.jpg" alt="Loreto Convent School Delhi Cantt" className="img-fluid"/></SlideshowLightbox>
                </div>
                <div>
                     <SlideshowLightbox><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/gal-1.jpg" alt="Loreto Convent School Delhi Cantt" className="img-fluid"/></SlideshowLightbox>
                </div> */}
             </div> 
          </div>
       </div>
    </div>
    </section>
   </>
  )
}

export default SubCategoryKindergarten
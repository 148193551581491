import React from 'react'
import { Link } from 'react-router-dom'
const AdmissionWithdrawal = () => {
  return (
    <>
    <section className="page-header">
        <div className="page-header-bg"></div>
        <div className="page-header-shape-1"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/page-header-shape-1.png" alt="Loreto Convent School, Delhi Cantt"/></div>
        <div className="container">
        <div className="page-header__inner">
            <ul className="thm-breadcrumb list-unstyled ml-0">
                <li><Link to="/"> Home</Link></li>
                <li> Prospectus </li>
                <li> Admission &  Withdrawal  </li>
            </ul>
            <h2>Admission &  Withdrawal </h2>
        </div>
        </div>
    </section>
    <section className="innerbody">
<div className="container">
<div className="row">
<div className="col-md-12">

<h1 className="head1">ADMISSION & WITHDRAWAL PROCEDURE</h1>

<p>Being on Defence Land 70% of the existing vacancies in our school in all classes are reserved for the children (Only Girls) of Defence Personnel and resident of Cantt. After considering the Defence Category, Catholics of the zone ate given preference for admission as Loreto Convent School is a Minority institution.</p>
<p><b>ADMISSION TO CLASS PREP</b></p>
<p>Our school starts with Class Prep which is equivalent to Upper KG Class Prep Admissions are initiated in accordance to the Directorate of Education Directives. The candidate applying for Class Prep must have completed 4 years of age by 31st March of the academic session applied for.</p>
<p><b>ADMISSlON TO CLASSES ONE TO NINE</b></p>
<p>Please check our website by the first/ second week of February of each year for details regarding admissions in the new session.</p>
<p><b>ADMISSION TO CLASS "XI"</b></p>
<p>Admission is given in the month of April on the basis of the CBSE Examination results. There are three sections, commerce, Science and Humanities.</p>
<p><b>GENERAL ADMISSION RULES</b></p>

<ul className="lilist">
<li>The management reserves the right to admit or refuse students seeking admission in the school.</li>
<li> Transfer certificate from the previous school is a must to confirm admission for Classes one to nine.</li>
<li>Payment of fees, at the time of admission, shall be payable through DD only.</li>
</ul>

<h1 className="head1">ISSUE OF CERTIFICATE FROM SCHOOL</h1>

<ul className="lilist">
<li>For the withdrawal of a pupil from the School, a calendar month's notice must be given in writing, otherwise a calendar month's fee will be charged in lieu of notice. Withdrawals in December and after require the last quarter's fees to be paid.</li>
<li>Transfer certificate can be obtained only after all dues have been paid. Duplicate copies of the Transfer Certificate a will be issued after verification, on payment of Rs. 100/-.</li>
<li>Parents are requested to download the report card from the school portal as soon as the result is announced.</li>
    <li>A duplicate copy of the report card can be obtained on payment of Rs. 100/- up to class X and Rs. 150/- for classes XI-XII.</li>
    <li>In case of loss of I-card, an application regarding the same is to be submitted along with payment of Rs 100/-.</li>
</ul>


</div>
</div>
</div>
</section>
   </>
  )
}

export default AdmissionWithdrawal
import React from 'react'
import { Link } from 'react-router-dom'
const ClassStrength = () => {
  return (
    <>
    <section class="page-header"><div class="page-header-bg"></div><div class="page-header-shape-1"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/page-header-shape-1.png" alt="Loreto Convent School, Delhi Cantt" /></div><div class="container"><div class="page-header__inner"><ul class="thm-breadcrumb list-unstyled ml-0"><li><a href="/"> Home</a></li><li>Prospectus </li><li> Class Strength  </li></ul><h2>Class Strength </h2></div></div></section>
  <section class="innerbody">
<div class="container">
<div class="row">

<div class="col-md-12">
<h3><b>Academic year 2024-25</b></h3>
<br />
<table class="table table-striped table-bordered">
<tbody>
  <tr >
            <td colspan="3"><strong>  CLASS STRENGTH AS ON 17.05.2024</strong>
              
            </td>
        </tr>
<tr> 
 <td> Junior School </td> 
 <td>Classes Prep to V </td> 
 <td> 614 </td> 

 </tr> 
 <tr> 
 <td> Middle School</td> 
 <td>Classes VI to VIII</td> 
 <td>358</td> 
 </tr> 


  <tr> 
 <td> Senior School</td> 
 <td>Classes IX to XII</td> 
 <td>393</td> 
 </tr> 



   <tr> 
 
  <td colspan="3">1365</td> 
 </tr> 

 

    </tbody>
</table>
</div>
<div class="py-4"></div>


</div>
</div>
</section>
   </>
  )
}

export default ClassStrength
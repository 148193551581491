import React from 'react'
import { Link } from 'react-router-dom'
const SchoolFee = () => {
  return (
    <>
    <section className="page-header">
        <div className="page-header-bg"></div>
        <div className="page-header-shape-1"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/page-header-shape-1.png" alt="Loreto Convent School, Delhi Cantt"/></div>
        <div className="container">
        <div className="page-header__inner">
            <ul className="thm-breadcrumb list-unstyled ml-0">
                <li><Link to="/"> Home</Link></li>
                <li> Prospectus </li>
                <li> School Fee  </li>
            </ul>
            <h2>School Fee </h2>
        </div>
        </div>
    </section>
    <section className="innerbody">
            <div className="container">
            <div className="row">
            <div className="col-md-12">
            <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/Fee-Structure.jpg" className="img-fluid d-block mx-auto" alt="Loreto Convent School, Delhi Cantt"/>
            </div>
            </div>
            </div>
   </section>
   </>
  )
}

export default SchoolFee
import React from 'react'
import { Link } from 'react-router-dom'
const Infrastructure = () => {
  return (
    <>
    <section className="page-header">
        <div className="page-header-bg"></div>
        <div className="page-header-shape-1"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/page-header-shape-1.png" alt="Loreto Convent School, Delhi Cantt"/></div>
        <div className="container">
        <div className="page-header__inner">
            <ul className="thm-breadcrumb list-unstyled ml-0">
                <li><Link to="/"> Home</Link></li>
                <li> About Us </li>
                <li> Infrastructure Facilities  </li>
            </ul>
            <h2>Infrastructure Facilities </h2>
        </div>
        </div>
    </section>
    <section className="innerbody">
<div className="container">
<div className="row">
<div className="col-md-12">
<table className="table table-striped table-bordered">

<tbody>
   <tr>
      <th colspan="2">
         <center>INFRASTRUCTURE DETAILS</center>
      </th>
   </tr>
   <tr>
      <td>CAMPUS AREA OF THE SCHOOL(IN SQ. M)</td>
      <td colspan="1">40468</td>
   </tr>
   <tr>
      <td>TOTAL BUILT UP AREA (IN SQ. M)</td>
      <td>24281</td>
   </tr>
   <tr>
      <td>NUMBER OF AUDITIORIUM</td>
      <td>01</td>
   </tr>
   <tr>
      <td>TOTAL SEATING CAPACITY OF THE AUDITORIUM</td>
      <td>1200</td>
   </tr>
   <tr>
      <td>NUMBER OF SCIENCE LABORATARIES</td>
      <td>03</td>
   </tr>
   <tr>
      <td>NUMBER OF COMPUTER LABS</td>
      <td>03</td>
   </tr>
   <tr>
      <td>NUMBER OF ROOMS</td>
      <td>39</td>
   </tr>
   <tr>
      <td>NUMBER OF PLAYGROUNDS</td>
      <td>05</td>
   </tr>
</tbody>
</table>
</div>
</div>
</div>
</section>
   </>
  )
}

export default Infrastructure
import React from 'react'
import { Link } from 'react-router-dom'
const MaryWardsStamp = () => {
  return (
    <>
    <section className="page-header">
        <div className="page-header-bg"></div>
        <div className="page-header-shape-1"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/page-header-shape-1.png" alt="Loreto Convent School, Delhi Cantt"/></div>
        <div className="container">
        <div className="page-header__inner">
            <ul className="thm-breadcrumb list-unstyled ml-0">
                <li><Link to="/"> Home</Link></li>
                <li> Founder </li>
                <li> Mary Wards Stamp  </li>
            </ul>
            <h2>Mary Wards Stamp </h2>
        </div>
        </div>
    </section>
    <section className="innerbody">
        <div className="container">
        <div className="col-md-12">
        <p className="text-center">
        West Bengal Governor M. K. Narayanan released a commemorative stamp at Kolkata in honor of Sister Mary Ward (1585-1645). This is the first Mary Ward stamp issued in the world.

        </p>
        <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LCSD/public/Images/mary_wards_stamp.jpg"  className="img-fluid mx-auto d-block" alt="Loreto Convent School, Delhi Cantt"/>
        </div>
        </div>
        </section>
   </>
  )
}

export default MaryWardsStamp